import { TenantDTO } from "@/models/tenant";
import axios, { AxiosResponse } from "axios";

const baseURL = process.env.VUE_APP_API_BASE_URL;

export default class HttpClient {
  static instance = axios.create({
    baseURL,
    timeout: 10000, // By default axios has no timeout, here we set it to 10 seconds to prevent waiting forever
    headers: {
      "Content-Type": "application/json",
    },
  });

  static setCulture(culture: string): void {
    HttpClient.instance.defaults.headers["Accept-Language"] = culture;
  }

  static setAuth(token: string | null): void {
    if (token != null) {
      HttpClient.instance.defaults.headers["Authorization"] = `Bearer ${token}`;
    } else {
      delete HttpClient.instance.defaults.headers["Authorization"];
    }
  }

  static setTenant(tenantUri: string | null): void {
    this.instance.defaults.baseURL =
      tenantUri != null
        ? `${baseURL}${encodeURIComponent(tenantUri)}/`
        : baseURL;
  }
  static getUrl = (path: string): string => baseURL + path;

  static getTenants(): Promise<AxiosResponse<TenantDTO[]>> {
    return this.instance.get<TenantDTO[]>("tenants", { baseURL });
  }
}
