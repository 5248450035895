
import { defineComponent } from "vue";

import PageHeading from "@/components/common/page-heading/PageHeading.vue";
import RouterLinkAuthWithIcon from "@/components/common/router-link-auth/RouterLinkAuthWithIcon.vue";
import Section from "@/components/common/section/Section.vue";
import { RouteNames } from "@/router/routeNames";

import { ButtonType } from "@/components/common/button/Button.types";

import NavigationTabContainer from "@/components/common/navigation/NavigationTabContainer.vue";
import NavigationTab from "@/components/common/navigation/NavigationTab.vue";

import { useStore } from "vuex";
import { RootState } from "@/store";
import dictionary from "@/dictionary";
import { Culture } from "@/enums";
import { ArrowNarrowLeftIcon } from "@heroicons/vue/solid";
import AccountForm from "./account/AccountForm.vue";

export default defineComponent({
  components: {
    PageHeading,
    Section,
    NavigationTab,
    NavigationTabContainer,
    RouterLinkAuthWithIcon,
    AccountForm,
  },
  setup() {
    // Store setup
    const store = useStore<RootState>();

    // Translations
    const texts = dictionary[store.getters["cultureStore/active"] as Culture];

    return {
      texts,
      ButtonType,
      ArrowNarrowLeftIcon,
      RouteNames,
    };
  },
});
