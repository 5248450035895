import { Component } from "vue";

interface ModalProps {
  visible: boolean;
  type: ModalType;
  icon?: Component;
  title: string;
  description: string;
}

enum ModalType {
  warning = "warning",
  danger = "danger",
}

export { ModalProps, ModalType };
