
import { defineComponent, computed } from "vue";
import dictionary from "@/dictionary";
import { useStore } from "vuex";
import { RootState } from "@/store";
import { Culture } from "@/enums";
import { KeyIcon } from "@heroicons/vue/outline";
import Account from "@/models/account";

import Button from "@/components/common/button/Button.vue";
import Checkbox from "@/components/common/checkbox/Checkbox.vue";
import PageHeading from "@/components/common/page-heading/PageHeading.vue";
import Error from "@/components/common/error/Error.vue";
import Loader from "@/components/common/loader/Loader.vue";
import Table from "@/components/common/table/Table.vue";
import TableRow from "@/components/common/table/TableRow.vue";
import TableHeader from "@/components/common/table/TableHeader.vue";
import TableColumn from "@/components/common/table/TableColumn.vue";
import Badge from "@/components/common/badge/Badge.vue";
import { BadgeType } from "@/components/common/badge/BadgeProps";
import Search from "@/components/common/search/Search.vue";
import RouterLinkAuth from "@/components/common/router-link-auth/RouterLinkAuth.vue";
import { RouteNames } from "@/router/routeNames";
import router from "@/router";

export default defineComponent({
  components: {
    Loader,
    Error,
    Button,
    Badge,
    Checkbox,
    PageHeading,
    Table,
    TableRow,
    TableHeader,
    TableColumn,
    Search,
    RouterLinkAuth,
  },
  setup() {
    const store = useStore<RootState>();

    // Translations
    const texts = dictionary[store.getters["cultureStore/active"] as Culture];

    // Accounts data
    store.dispatch("accountStore/loadAccounts");

    const accounts = computed<Account[]>(() =>
      store.getters["accountStore/accounts"].filter(
        (account: Account) =>
          (search.value == null ||
            account.name.toLowerCase().indexOf(search.value.toLowerCase()) >=
              0 ||
            account.email.toLowerCase().indexOf(search.value.toLowerCase()) >=
              0) &&
          (includeBlockedAccounts.value || !account.isBlocked)
      )
    );

    const loadingAccounts = computed<boolean>(
      () => store.getters["accountStore/loadingAccounts"]
    );

    const errorLoadingAccounts = computed<boolean>(
      () => store.getters["accountStore/errorLoadingAccounts"]
    );

    const goToCreateAccountPage = () => {
      router.push({ name: RouteNames.ACCOUNT_CREATE });
    };

    // Loading
    const loadingPage = computed<boolean>(() => loadingAccounts.value);

    // Errors
    const errorLoadingData = computed<boolean>(
      () => errorLoadingAccounts.value
    );

    // Filters
    const search = computed<string | undefined>({
      get: () => store.getters["accountStore/search"] || undefined,
      set: (val) => {
        store.commit("accountStore/setSearch", val || null);
      },
    });

    const includeBlockedAccounts = computed<boolean>({
      get: () => store.getters["accountStore/includeBlockedAccounts"],
      set: (val) => {
        store.commit("accountStore/setIncludeBlockedAccounts", val);
      },
    });

    return {
      accounts,
      loadingPage,
      errorLoadingData,
      KeyIcon,
      BadgeType,
      texts,
      search,
      includeBlockedAccounts,
      RouteNames,
      goToCreateAccountPage,
    };
  },
});
