
import CulturePicker from "./CulturePicker.vue";
import { computed, defineComponent, inject, ref } from "vue";
import {
  Dialog,
  DialogOverlay,
  TransitionChild,
  TransitionRoot,
  Menu,
  MenuButton,
  MenuItem,
  MenuItems,
} from "@headlessui/vue";

import PrimaryNavigation from "./primary-navigation/PrimaryNavigation.vue";
import OtherNavigation from "./OtherNavigation.vue";

import summitLogoSrc from "@/assets/images/logo-summit.svg";
import {
  XIcon,
  MenuAlt2Icon,
  SwitchHorizontalIcon,
} from "@heroicons/vue/outline";

import dictionary from "@/dictionary";
import { Culture } from "@/enums";
import { useStore } from "vuex";
import { RootState } from "@/store";
import { IAuthPlugin, User } from "@/plugins/authentication";
import RouterLinkAuth from "@/components/common/router-link-auth/RouterLinkAuth.vue";
import { Tenant } from "@/models/tenant";

export default defineComponent({
  name: "ApplicationShell",
  props: {},
  components: {
    CulturePicker,
    PrimaryNavigation,
    Dialog,
    DialogOverlay,
    TransitionChild,
    TransitionRoot,
    MenuAlt2Icon,
    SwitchHorizontalIcon,
    XIcon,
    Menu,
    MenuButton,
    MenuItem,
    MenuItems,
    RouterLinkAuth,
    OtherNavigation,
  },

  setup() {
    const auth = inject<IAuthPlugin>("Auth");
    if (!auth) throw new Error("Missing auth plugin");

    const store = useStore<RootState>();
    const user = computed<User | null>(() => store.getters["authStore/user"]);
    const tenant = computed<Tenant | null>(
      () => store.getters["authStore/tenant"]
    );
    const texts = dictionary[store.getters["cultureStore/active"] as Culture];
    const sidebarOpen = ref(false);

    return {
      summitLogoSrc,
      sidebarOpen,
      user,
      tenant,
      logout: auth.logout,
      texts,
    };
  },
});
