<template>
  <a
    data-testid="anchor"
    download
    v-if="downloadUrl && !disabled"
    :href="downloadUrl"
    :class="classes"
  >
    {{ label }}</a
  >
  <button
    data-testid="button"
    type="button"
    v-else
    :disabled="buttonDisabled"
    :class="classes"
  >
    <span v-if="loading" :class="spinnerClasses"></span>
    {{ label }}
  </button>
</template>

<script lang="ts">
import { computed, defineComponent, PropType } from "vue";
import {
  ButtonSize,
  ButtonType,
} from "@/components/common/button/Button.types";

export default defineComponent({
  name: "Button",
  props: {
    size: {
      type: String as PropType<ButtonSize>,
      required: false,
      default: ButtonSize.md,
    },
    type: {
      type: String as PropType<ButtonType>,
      required: false,
      default: ButtonType.primary,
    },
    label: {
      type: String,
      required: true,
    },
    downloadUrl: {
      type: String,
      required: false,
    },
    disabled: {
      type: Boolean,
      required: false,
    },
    loading: {
      type: Boolean,
      required: false,
    },
  },
  setup(props) {
    const buttonDisabled = computed<boolean>(
      () => props.disabled || props.loading
    );

    const spinnerClasses = computed(() => [
      "animate-spin h-5 w-5 mr-3 border-4 rounded-full",
      {
        "spinner-color-primary": props.type === ButtonType.primary,
        "spinner-color-secondary": props.type === ButtonType.secondary,
        "spinner-color-white": props.type === ButtonType.white,
        "spinner-color-danger": props.type === ButtonType.danger,
        "spinner-color-warning": props.type === ButtonType.warning,
      },
    ]);
    const classes = computed(() => [
      "inline-flex items-center justify-center border font-medium focus:outline-none focus:ring-2 focus:ring-offset-2",
      {
        "text-xs px-2.5 py-1.5 rounded": props.size === ButtonSize.xs,
        "text-sm px-3 py-2 rounded-md leading-4": props.size === ButtonSize.sm,
        "text-sm px-4 py-2 rounded-md": props.size === ButtonSize.md,
        "text-base px-4 py-2 rounded-md": props.size === ButtonSize.lg,
        "text-base px-6 py-3 rounded-md": props.size === ButtonSize.xl,
      },
      {
        "text-white bg-brightgreen-500 shadow-sm border-transparent":
          props.type === ButtonType.primary,
        "text-brightgreen-700 bg-brightgreen-100 border-transparent":
          props.type === ButtonType.secondary,
        "text-gray-700 bg-white shadow-sm border-gray-300":
          props.type === ButtonType.white,
        "text-white bg-red-600 shadow-sm border-transparent":
          props.type === ButtonType.danger,
        "text-yellow-700 bg-yellow-100 shadow-sm border-transparent":
          props.type === ButtonType.warning,
      },
      !buttonDisabled.value && {
        "hover:bg-brightgreen-600 focus:ring-brightgreen-500 ":
          props.type === ButtonType.primary,
        "hover:bg-brightgreen-200 focus:ring-brightgreen-500 ":
          props.type === ButtonType.secondary,
        "hover:bg-gray-50 focus:ring-brightgreen-500":
          props.type === ButtonType.white,
        "hover:bg-red-700 focus:ring-red-500": props.type === ButtonType.danger,
        "hover:bg-yellow-200 focus:ring-yellow-500":
          props.type === ButtonType.warning,
      },
      {
        "opacity-50 cursor-not-allowed": buttonDisabled.value,
      },
    ]);

    return {
      classes,
      spinnerClasses,
      buttonDisabled,
    };
  },
});
</script>

<style scoped>
.spinner-color-primary {
  @apply border-white;
  border-top-color: #00c890; /* brightgreen-500 */
}
.spinner-color-secondary {
  @apply border-brightgreen-700;
  border-top-color: #beffed; /* brightgreen-100*/
}
.spinner-color-white {
  @apply border-gray-700;
  border-top-color: white; /* white */
}
.spinner-color-danger {
  @apply border-white;
  border-top-color: #dc2626; /* red-600 */
}
.spinner-color-warning {
  @apply border-yellow-700;
  border-top-color: #fff1c8; /* yellow-100*/
}
</style>
