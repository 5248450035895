<template>
  <PrimaryNavigationItem
    :to="{ name: RouteNames.ACCOUNTS }"
    :name="texts.navigationItems.accounts.title"
    indent
  />
</template>

<script lang="ts">
import { defineComponent } from "vue";
import PrimaryNavigationItem from "./PrimaryNavigationItem.vue";

import { RouteNames } from "@/router/routeNames";

import { HomeIcon, CalendarIcon, CogIcon } from "@heroicons/vue/outline";
import dictionary from "@/dictionary";
import { useStore } from "vuex";
import { RootState } from "@/store";
import { Culture } from "@/enums";

export default defineComponent({
  components: {
    PrimaryNavigationItem,
  },
  setup() {
    const store = useStore<RootState>();
    const texts = dictionary[store.getters["cultureStore/active"] as Culture];

    return {
      texts,
      RouteNames,
      HomeIcon,
      CalendarIcon,
      CogIcon,
    };
  },
});
</script>
