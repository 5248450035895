import { Component } from "vue";

export interface TextInputProps {
  id?: string;
  name?: string;
  modelValue?: string;
  type?: TextInputType;
  placeholder?: string;
  valid?: boolean;
  disabled?: boolean;
  icon?: Component;
}

export enum TextInputType {
  TEXT = "text",
  EMAIL = "email",
  SEARCH = "search",
}
