
import { defineComponent, PropType } from "vue";

export default defineComponent({
  name: "NavigationTabVertical",
  props: {
    name: {
      type: String as PropType<string>,
      required: true,
    },
    icon: {
      type: Function,
    },
    count: {
      type: Number as PropType<number>,
    },
    href: {
      type: String as PropType<string>,
    },
    current: {
      type: Boolean as PropType<boolean>,
    },
    dark: {
      type: Boolean as PropType<boolean>,
      default: false,
    },
    indent: {
      type: Boolean as PropType<boolean>,
      default: false,
    },
    expandedArrow: {
      type: Boolean as PropType<boolean>,
      default: undefined,
    },
  },
});
