
import { computed, defineComponent, PropType } from "vue";
import checkboxClasses from "./Checkbox";

export default defineComponent({
  name: "Checkbox",
  props: {
    modelValue: Boolean as PropType<boolean>,
  },
  setup(props, { emit }) {
    const isChecked = computed({
      get: () => props.modelValue,
      set: (checkedValue) => emit("update:modelValue", checkedValue),
    });
    const onChange = (event: Event): void => {
      isChecked.value = (event?.target as HTMLInputElement).checked;
    };

    return {
      isChecked,
      onChange,
      checkboxClasses,
    };
  },
});
