
import { defineComponent } from "vue";
import { RouteLocationRaw, useLink, useRouter } from "vue-router";
import { userCanGoTo } from "@/router/helpers/userCanGoTo";

export default defineComponent({
  setup(_props, ctx) {
    const router = useRouter();
    const to = ctx.attrs.to as RouteLocationRaw;
    const toRoute = router.resolve(to);
    return {
      visible: userCanGoTo(toRoute),
      to,
      ...useLink({ to }),
    };
  },
});
