<template>
  <MenuItem v-slot="{ active }" :disabled="disabled">
    <a
      :class="[
        active ? 'bg-gray-100 text-gray-900' : 'text-gray-700',
        icon
          ? 'group flex items-center px-4 py-2 text-sm'
          : 'block px-4 py-2 text-sm',
        disabled ? 'pointer-events-none opacity-50' : 'cursor-pointer',
      ]"
    >
      <component
        v-if="icon"
        :is="icon"
        class="mr-3 h-5 w-5 text-gray-400 group-hover:text-gray-500"
        aria-hidden="true"
      />
      {{ label }}
    </a>
  </MenuItem>
</template>

<script lang="ts">
import { Component, defineComponent, PropType } from "vue";
import { MenuItem } from "@headlessui/vue";

export interface DropdownItemProps {
  label: string;
  icon?: Component;
  active: boolean;
}

export default defineComponent({
  name: "DropdownItem",
  props: {
    label: {
      type: String as PropType<string>,
      required: true,
    },
    icon: {
      type: Function,
    },
    active: {
      type: Boolean as PropType<boolean>,
      required: false,
      default: true,
    },
    disabled: {
      type: Boolean as PropType<boolean>,
      default: false,
    },
  },
  components: {
    MenuItem,
  },
});
</script>
