
import { defineComponent, PropType } from "vue";
import Badge from "@/components/common/badge/Badge.vue";
import { BadgeType } from "@/components/common/badge/BadgeProps";

export interface PageHeadingProps {
  title: string;
  icon?: Record<string, unknown>;
  badgeLabel?: string;
  badgeType?: BadgeType;
}

export default defineComponent({
  name: "PageHeading",
  components: {
    Badge,
  },
  props: {
    title: {
      type: String as PropType<string>,
      required: true,
    },
    icon: {
      type: Function,
    },
    badgeLabel: {
      type: String as PropType<string>,
    },
    badgeType: {
      type: String as PropType<BadgeType>,
    },
  },
});
