<template>
  <div
    aria-live="assertive"
    class="inset-0 px-4 pb-4 pointer-events-none"
    data-testid="notification"
  >
    <div>
      <transition
        enter-active-class="transform ease-out duration-300 transition"
        enter-from-class="translate-y-2 opacity-0 sm:translate-y-0 sm:translate-x-2"
        enter-to-class="translate-y-0 opacity-100 sm:translate-x-0"
        leave-active-class="transition ease-in duration-100"
        leave-from-class="opacity-100"
        leave-to-class="opacity-0"
      >
        <div
          class="max-w-sm w-full bg-white shadow-lg rounded-lg pointer-events-auto ring-1 ring-black ring-opacity-5 overflow-hidden"
        >
          <div class="p-4">
            <div class="flex items-start">
              <div class="flex-shrink-0">
                <CheckCircleIcon
                  v-if="type === NotificationType.success"
                  class="h-6 w-6 text-brightgreen-500"
                  aria-hidden="true"
                />
                <XCircleIcon
                  v-if="type === NotificationType.failure"
                  class="h-6 w-6 text-red-400"
                  aria-hidden="true"
                />
                <ExclamationCircleIcon
                  v-if="type === NotificationType.warning"
                  class="h-6 w-6 text-yellow-400"
                  aria-hidden="true"
                />
                <InformationCircleIcon
                  v-if="type === NotificationType.info"
                  class="h-6 w-6 text-blue-400"
                  aria-hidden="true"
                />
              </div>
              <div class="ml-3 w-0 flex-1 pt-0.5">
                <p
                  class="text-sm font-medium text-gray-900"
                  data-testid="title"
                >
                  {{ title }}
                </p>
                <p class="mt-1 text-sm text-gray-500" data-testid="content">
                  {{ content }}
                </p>
              </div>
              <div class="ml-4 flex-shrink-0 flex">
                <button
                  @click="close"
                  data-testid="close"
                  class="bg-white rounded-md inline-flex text-gray-400 hover:text-gray-500 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-brightgreen-500"
                >
                  <span class="sr-only">Close</span>
                  <XIcon class="h-5 w-5" aria-hidden="true" />
                </button>
              </div>
            </div>
          </div>
        </div>
      </transition>
    </div>
  </div>
</template>

<script lang="ts">
import { defineComponent, PropType } from "vue";
import { XIcon } from "@heroicons/vue/solid";
import {
  CheckCircleIcon,
  XCircleIcon,
  ExclamationCircleIcon,
  InformationCircleIcon,
} from "@heroicons/vue/outline";
import { NotificationType } from "@/components/common/notification/Notification.types";

export default defineComponent({
  props: {
    type: {
      type: String as PropType<NotificationType>,
      required: true,
    },
    title: {
      type: String as PropType<string>,
      required: true,
    },
    content: {
      type: String as PropType<string>,
      required: true,
    },
    close: {
      type: Function as PropType<() => void>,
      required: true,
    },
  },
  components: {
    XIcon,
    CheckCircleIcon,
    XCircleIcon,
    ExclamationCircleIcon,
    InformationCircleIcon,
  },
  setup() {
    return {
      NotificationType,
    };
  },
});
</script>
