<template>
  <td
    :class="`px-6 py-4 whitespace-nowrap text-sm ${
      accent ? 'font-medium text-gray-900' : 'text-gray-500'
    }`"
  >
    <slot></slot>
  </td>
</template>

<script lang="ts">
import { defineComponent } from "vue";

export default defineComponent({
  name: "TableColumn",
  props: {
    accent: Boolean,
  },
});
</script>
