<template>
  <a
    :href="href"
    :class="[
      current
        ? 'border-brightgreen-500 text-brightgreen-600'
        : 'border-transparent text-gray-500 hover:text-gray-700 hover:border-gray-300',
      'group inline-flex items-center pb-4 px-1 border-b-2 font-medium text-sm',
    ]"
    data-testid="navigation-tab"
  >
    <component
      v-if="icon"
      :is="icon"
      :class="[
        current
          ? 'text-brightgreen-500'
          : 'text-gray-400 group-hover:text-gray-500',
        '-ml-0.5 mr-2 h-5 w-5',
      ]"
      aria-hidden="true"
    />
    <span data-testid="navigation-tab-name">{{ name }}</span>
    <span
      v-if="count"
      :class="[
        current
          ? 'bg-brightgreen-100 text-brightgreen-600'
          : 'bg-gray-100 text-gray-900',
        'hidden ml-3 py-0.5 px-2.5 rounded-full text-xs font-medium md:inline-block',
      ]"
      >{{ count }}</span
    >
  </a>
</template>

<script lang="ts">
import { defineComponent, PropType } from "vue";

export default defineComponent({
  name: "NavigationTab",
  props: {
    name: {
      type: String as PropType<string>,
      required: true,
    },
    icon: {
      type: Function,
    },
    count: {
      type: Number as PropType<number>,
    },
    href: {
      type: String as PropType<string>,
    },
    current: {
      type: Boolean as PropType<boolean>,
    },
  },
});
</script>
