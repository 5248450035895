
import { defineComponent } from "@vue/runtime-core";
import { TransitionRoot } from "@headlessui/vue";
import { ref } from "vue";

export default defineComponent({
  name: "Loader",
  components: {
    TransitionRoot,
  },
  setup() {
    const showLoader = ref(false);
    const showLoaderDelayMs = 500;

    setTimeout(() => (showLoader.value = true), showLoaderDelayMs);
    return { showLoader };
  },
});
