<template>
  <div
    class="w-full flex justify-center items-center mt-4"
    data-testid="loader"
  >
    <TransitionRoot
      appear
      enter="ease-in-out duration-200"
      enter-from="opacity-0"
      enter-to="opacity-100"
      :show="showLoader"
    >
      <div class="loader"></div>
    </TransitionRoot>
  </div>
</template>

<script lang="ts">
import { defineComponent } from "@vue/runtime-core";
import { TransitionRoot } from "@headlessui/vue";
import { ref } from "vue";

export default defineComponent({
  name: "Loader",
  components: {
    TransitionRoot,
  },
  setup() {
    const showLoader = ref(false);
    const showLoaderDelayMs = 500;

    setTimeout(() => (showLoader.value = true), showLoaderDelayMs);
    return { showLoader };
  },
});
</script>

<style scoped>
.loader {
  @apply animate-spin h-12 w-12 border-6 rounded-full border-gray-300;
  border-top-color: #00c890; /* brightgreen */
}
</style>
