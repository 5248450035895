
import { defineComponent, PropType } from "@vue/runtime-core";
import { useField } from "vee-validate";

import { SelectOption } from "./SelectOption";
import Select from "./Select.vue";
import { computed } from "vue";

export default defineComponent({
  components: {
    Select,
  },
  props: {
    formFieldId: {
      type: String as PropType<string>,
      required: true,
    },
    items: {
      type: Array as PropType<SelectOption[]>,
      required: true,
    },
    label: String as PropType<string>,
    disabled: Boolean as PropType<boolean>,
    allowUnset: Boolean as PropType<boolean>,
  },
  setup(props) {
    const { value, errorMessage } = useField<string | undefined>(
      props.formFieldId
    );

    const selectedOption = computed<SelectOption | undefined>({
      get: () => {
        return props.items.filter((item) => item.value === value.value)[0];
      },
      set: (newValue) => {
        value.value = newValue?.value;
      },
    });

    return {
      value,
      errorMessage,
      selectedOption,
    };
  },
});
