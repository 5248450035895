
import { RootState } from "@/store";
import { computed, defineComponent } from "vue";
import { useStore } from "vuex";
import { Tenant } from "@/models/tenant";
import { CollectionIcon } from "@heroicons/vue/outline";
import Error from "@/components/common/error/Error.vue";
import Loader from "@/components/common/loader/Loader.vue";
import Button from "@/components/common/button/Button.vue";
import PageHeading from "@/components/common/page-heading/PageHeading.vue";
import Table from "@/components/common/table/Table.vue";
import TableRow from "@/components/common/table/TableRow.vue";
import TableHeader from "@/components/common/table/TableHeader.vue";
import TableColumn from "@/components/common/table/TableColumn.vue";
import { useRouter } from "vue-router";
import { notify } from "@kyvg/vue3-notification";
import { NotificationType } from "@/components/common/notification/Notification.types";

export default defineComponent({
  name: "tenant",
  components: {
    Loader,
    Error,
    Button,
    PageHeading,
    Table,
    TableRow,
    TableHeader,
    TableColumn,
  },
  setup() {
    const store = useStore<RootState>();
    const router = useRouter();

    const switchTenant = (tenant: Tenant) => {
      store
        .dispatch("authStore/switchTenant", tenant.uri)
        .then(() => {
          router.push(
            router.currentRoute.value.redirectedFrom?.fullPath || "/"
          );
        })
        .catch((e) => {
          // errorLoadingTenant is reset to prevent App.vue from showing the error component.
          store.commit("authStore/setErrorLoadingTenant", false);

          notify({
            title: "Klantgegevens ophalen mislukt",
            text:
              "Het is niet gelukt de klantgegevens op te halen. Probeer het a.u.b. opnieuw.",
            type: NotificationType.failure,
          });
          throw e;
        });
    };

    store.dispatch("authStore/loadTenants");

    const tenants = computed<Tenant[]>(
      () => store.getters["authStore/tenants"]
    );

    const loadingTenants = computed<boolean>(
      () => store.getters["authStore/loadingTenants"]
    );

    const errorLoadingTenants = computed<boolean>(
      () => store.getters["authStore/errorLoadingTenants"]
    );

    // Loading
    const loadingPage = computed<boolean>(() => loadingTenants.value);

    // Errors
    const errorLoadingData = computed<boolean>(() => errorLoadingTenants.value);

    return {
      loadingPage,
      errorLoadingData,
      tenants,
      switchTenant,
      CollectionIcon,
    };
  },
});
