
import { defineComponent, PropType } from "@vue/runtime-core";
import { useField } from "vee-validate";
import { computed } from "vue";

import { CheckboxFieldItem } from "./CheckboxField.types";

export default defineComponent({
  props: {
    formFieldId: {
      type: String as PropType<string>,
      required: true,
    },
    items: {
      type: Array as PropType<CheckboxFieldItem[]>,
      required: true,
    },
    label: String as PropType<string>,
  },
  setup(props) {
    const { value, errorMessage } = useField<string[] | undefined>(
      props.formFieldId
    );

    const checkedValues = computed<string[]>({
      get: () => value.value || [],
      set: (newValue) => {
        value.value = newValue.length > 0 ? newValue : undefined;
      },
    });

    return {
      checkedValues,
      errorMessage,
    };
  },
});
