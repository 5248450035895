
import {
  Listbox,
  ListboxButton,
  ListboxLabel,
  ListboxOption,
  ListboxOptions,
} from "@headlessui/vue";
import { CheckIcon, SelectorIcon } from "@heroicons/vue/solid";
import { computed, defineComponent, PropType } from "vue";
import { SelectOption } from "./SelectOption";

export interface SelectProps {
  options: SelectOption[];
  optionsPlaceholder?: string;
  label?: string;
  modelValue?: SelectOption;
  disabled?: boolean;
  allowUnset?: boolean;
}

export default defineComponent({
  components: {
    Listbox,
    ListboxButton,
    ListboxLabel,
    ListboxOption,
    ListboxOptions,
    CheckIcon,
    SelectorIcon,
  },
  props: {
    options: {
      type: Array as PropType<SelectOption[]>,
      required: true,
      validator: (value: []) => {
        return value.length > 0;
      },
    },
    optionsPlaceholder: {
      type: String,
    },
    label: {
      type: String,
    },
    modelValue: {
      type: [Object, null] as PropType<SelectOption | null>,
    },
    disabled: {
      type: Boolean,
    },
    allowUnset: {
      type: Boolean,
    },
  },
  setup(props, { emit }) {
    const selected = computed<SelectOption | null | undefined>({
      get: () => props.modelValue,
      set: (value) => emit("update:modelValue", value),
    });

    const inputClass = computed(() => [
      {
        "text-gray-500 placeholder-gray-300 bg-gray-100": props.disabled,
      },
      {
        "bg-white": !props.disabled,
      },
    ]);

    return {
      selected,
      inputClass,
    };
  },
});
