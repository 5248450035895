
import { Component, defineComponent, PropType } from "vue";
import RouterLinkAuth from "@/components/common/router-link-auth/RouterLinkAuth.vue";
import { RouterLinkProps } from "vue-router";

export enum IconAlignment {
  Left = "left",
  Right = "right",
}

export interface RouterLinkWithIconProps extends RouterLinkProps {
  text: string;
  icon: Component;
  iconAlignment: IconAlignment;
}

export default defineComponent({
  name: "RouterLinkWithIcon",
  components: {
    RouterLinkAuth,
  },
  props: {
    text: {
      type: String,
      required: true,
    },
    icon: {
      type: Function,
      required: true,
    },
    iconAlignment: {
      type: String as PropType<IconAlignment>,
      required: false,
      default: IconAlignment.Left,
    },
  },
  setup() {
    return { IconAlignment };
  },
});
