
import dictionary from "@/dictionary";
import { Culture } from "@/enums";
import { redirectToOldDashboard } from "@/router/helpers/redirectToOldDashboard";
import { RootState } from "@/store";
import { computed, defineComponent } from "vue";
import { useStore } from "vuex";

export default defineComponent({
  name: "OtherNavigation",
  setup() {
    const store = useStore<RootState>();

    const texts = dictionary[store.getters["cultureStore/active"] as Culture];
    // A user can be redirected if the hostLoginId is set
    const userCanBeRedirectedToOldDashboard = computed<boolean>(
      () => store.getters["authStore/hostLoginId"]
    );

    const tenant = computed(() => store.getters["authStore/tenant"]);
    const token = computed(() => store.getters["authStore/token"]);

    const redirectUserToOldDashboard = () => {
      store.commit("authStore/setRedirectingToOldDashboard", true);
      redirectToOldDashboard(tenant.value.name, token.value);
    };

    return {
      texts,
      redirectUserToOldDashboard,
      userCanBeRedirectedToOldDashboard,
    };
  },
});
