import store from "@/store";
import { Permission } from "@/store/modules/auth.store";
import { RouteLocation } from "vue-router";

const userCanGoTo = (location: RouteLocation): boolean => {
  // This line of code ensures we can run e2e tests with Cypress without authenticating
  // and will not be compiled to production by checking the NODE_ENV
  // before checking window.Cypress
  // eslint-disable-next-line @typescript-eslint/ban-ts-comment
  // @ts-ignore
  if (process.env.NODE_ENV === "development" && window.Cypress) {
    return true;
  }
  const userPermissions: Permission[] = store.getters["authStore/permissions"];
  const requiredPermissions = location.meta["permissions"] as
    | Permission[]
    | undefined;

  if (!requiredPermissions) return true;

  return requiredPermissions.every((required) =>
    userPermissions.includes(required)
  );
};

export { userCanGoTo };
