<template>
  <div class="flex items-center">
    <span
      data-testid="spinner"
      v-if="loading"
      :class="[
        'h-5 w-5 border-4 rounded-full spinner-color-primary animate-spin',
        {
          'mr-3 order-first': origin === DropdownOrigin.TopRight,
          'ml-3 order-last': origin === DropdownOrigin.TopLeft,
        },
      ]"
    ></span>
    <Menu as="div" class="relative inline-block text-left">
      <div>
        <MenuButton
          data-testid="dropdown-label"
          class="inline-flex justify-center w-full rounded-md border border-gray-300 shadow-sm px-4 py-2 bg-white text-sm font-medium text-gray-700 hover:bg-gray-50 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-offset-gray-100 focus:ring-brightgreen-500"
          >{{ label
          }}<ChevronDownIcon class="-mr-1 ml-2 h-5 w-5" aria-hidden="true" />
        </MenuButton>
      </div>

      <transition
        enter-active-class="transition ease-out duration-100"
        enter-from-class="transform opacity-0 scale-95"
        enter-to-class="transform opacity-100 scale-100"
        leave-active-class="transition ease-in duration-75"
        leave-from-class="transform opacity-100 scale-100"
        leave-to-class="transform opacity-0 scale-95"
      >
        <MenuItems
          :class="[
            'absolute mt-2 w-56 rounded-md shadow-lg bg-white ring-1 ring-black ring-opacity-5 focus:outline-none',
            { 'origin-top-left left-0': origin === DropdownOrigin.TopLeft },
            { 'origin-top-right right-0': origin === DropdownOrigin.TopRight },
            { 'divide-y divide-gray-100': divider },
          ]"
        >
          <slot></slot>
        </MenuItems>
      </transition>
    </Menu>
  </div>
</template>

<script lang="ts">
import { defineComponent, PropType } from "vue";
import { Menu, MenuButton, MenuItems } from "@headlessui/vue";

import { ChevronDownIcon } from "@heroicons/vue/solid";

export enum DropdownOrigin {
  TopLeft = "top-left",
  TopRight = "top-right",
}

export interface DropdownProps {
  label: string;
  origin: DropdownOrigin;
  divider?: boolean;
  loading?: boolean;
}

export default defineComponent({
  name: "Dropdown",
  props: {
    label: {
      type: String as PropType<string>,
      required: true,
    },
    origin: {
      type: String as PropType<DropdownOrigin>,
      required: false,
      default: DropdownOrigin.TopLeft,
    },
    divider: {
      type: Boolean as PropType<boolean>,
      required: false,
      default: false,
    },
    loading: {
      type: Boolean as PropType<boolean>,
      required: false,
      default: false,
    },
  },
  components: {
    Menu,
    MenuButton,
    MenuItems,
    ChevronDownIcon,
  },
  setup() {
    return {
      DropdownOrigin,
    };
  },
});
</script>

<style scoped>
.spinner-color-primary {
  border-top-color: #00c890; /* brightgreen-500 */
}
</style>
