
import { defineComponent, PropType } from "vue";
import { XIcon } from "@heroicons/vue/solid";
import {
  CheckCircleIcon,
  XCircleIcon,
  ExclamationCircleIcon,
  InformationCircleIcon,
} from "@heroicons/vue/outline";
import { NotificationType } from "@/components/common/notification/Notification.types";

export default defineComponent({
  props: {
    type: {
      type: String as PropType<NotificationType>,
      required: true,
    },
    title: {
      type: String as PropType<string>,
      required: true,
    },
    content: {
      type: String as PropType<string>,
      required: true,
    },
    close: {
      type: Function as PropType<() => void>,
      required: true,
    },
  },
  components: {
    XIcon,
    CheckCircleIcon,
    XCircleIcon,
    ExclamationCircleIcon,
    InformationCircleIcon,
  },
  setup() {
    return {
      NotificationType,
    };
  },
});
