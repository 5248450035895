<template>
  <Loader v-if="loading" data-testid="loading"></Loader>
  <Error data-testid="error" v-if="error"></Error>

  <div data-testid="main" v-if="!loading && !error && !!account">
    <Section>
      <RouterLinkAuthWithIcon
        data-testid="link-to-accounts"
        :to="{ name: RouteNames.ACCOUNTS }"
        :icon="ArrowNarrowLeftIcon"
        :text="texts.actions.backToOverview"
      ></RouterLinkAuthWithIcon>
      <PageHeading
        :title="account.name"
        :badgeLabel="
          account.isBlocked ? texts.status.blocked : texts.status.active
        "
        :badgeType="account.isBlocked ? BadgeType.danger : BadgeType.success"
      >
        <template v-slot:actions>
          <DropDown
            data-testid="actions"
            :label="texts.actions.actions"
            :origin="DropdownOrigin.TopRight"
          >
            <DropdownItem
              data-testid="block"
              :label="texts.actions.block"
              :icon="BanIcon"
              :disabled="matchesUser(account)"
              v-if="!account.isBlocked"
              @click="toggleBlockModal()"
            ></DropdownItem>
            <DropdownItem
              data-testid="unblock"
              :label="texts.actions.unblock"
              :icon="BadgeCheckIcon"
              v-if="account.isBlocked"
              :disabled="matchesUser(account)"
              @click="toggleBlockModal()"
            ></DropdownItem>
            <DropdownItem
              data-testid="delete"
              :label="texts.actions.delete"
              :icon="TrashIcon"
              :disabled="matchesUser(account)"
              @click="toggleDeleteModal()"
            ></DropdownItem>
          </DropDown>
        </template>
      </PageHeading>
      <NavigationTabContainer>
        <NavigationTab
          :name="texts.navigationItems.account.navigationTabs.details"
          :current="true"
        />
      </NavigationTabContainer>
    </Section>
    <AccountForm :account="account" />

    <!-- Confirm (un)block modal -->
    <Modal
      :type="ModalType.warning"
      :description="
        account.isBlocked
          ? texts.navigationItems.account.modal.unblockAccount.description
          : texts.navigationItems.account.modal.blockAccount.description
      "
      :title="
        account.isBlocked
          ? texts.navigationItems.account.modal.unblockAccount.title
          : texts.navigationItems.account.modal.blockAccount.title
      "
      :icon="ExclamationIcon"
      v-model:visible="blockModalVisible"
    >
      <template v-slot:buttons>
        <div class="mt-5 sm:mt-4 sm:flex sm:flex-row-reverse">
          <Button
            :data-testid="
              account.isBlocked ? 'confirm-unblock' : 'confirm-block'
            "
            class="sm:ml-3"
            :type="ButtonType.warning"
            :label="
              account.isBlocked ? texts.actions.unblock : texts.actions.block
            "
            :loading="actionLoading"
            :disabled="!blockModalVisible"
            @click="toggleBlock(account)"
          />
          <Button
            class="mt-3 sm:mt-0"
            :type="ButtonType.white"
            :label="texts.actions.cancel"
            :disabled="actionLoading || !blockModalVisible"
            @click="toggleBlockModal"
          />
        </div>
      </template>
    </Modal>

    <!-- Confirm delete modal -->
    <Modal
      :type="ModalType.danger"
      :description="
        texts.navigationItems.account.modal.deleteAccount.description
      "
      :title="texts.navigationItems.account.modal.deleteAccount.title"
      :icon="ExclamationIcon"
      v-model:visible="deleteModalVisible"
    >
      <template v-slot:buttons>
        <div class="mt-5 sm:mt-4 sm:flex sm:flex-row-reverse">
          <Button
            data-testid="confirm-delete"
            class="sm:ml-3"
            :type="ButtonType.danger"
            :label="texts.actions.delete"
            :loading="actionLoading"
            :disabled="!deleteModalVisible"
            @click="deleteAccount(account)"
          />
          <Button
            class="mt-3 sm:mt-0"
            :type="ButtonType.white"
            :label="texts.actions.cancel"
            :disabled="actionLoading || !deleteModalVisible"
            @click="toggleDeleteModal"
          />
        </div>
      </template>
    </Modal>
  </div>
</template>

<script lang="ts">
import { defineComponent, inject } from "vue";
import { BanIcon, BadgeCheckIcon, TrashIcon } from "@heroicons/vue/outline";
import Account from "@/models/account";
import { ref } from "vue";

import {
  getAccount as getAcountApiCall,
  blockAccount,
  unblockAccount,
  deleteAccount as deleteAccountApiCall,
} from "@/services/account.service";
import { useRoute, useRouter } from "vue-router";

import PageHeading from "@/components/common/page-heading/PageHeading.vue";
import RouterLinkAuthWithIcon from "@/components/common/router-link-auth/RouterLinkAuthWithIcon.vue";
import Section from "@/components/common/section/Section.vue";
import Loader from "@/components/common/loader/Loader.vue";
import Error from "@/components/common/error/Error.vue";
import Modal from "@/components/common/modal/Modal.vue";
import { ModalType } from "@/components/common/modal/Modal.types";
import Button from "@/components/common/button/Button.vue";
import { ButtonType } from "@/components/common/button/Button.types";
import { ExclamationIcon } from "@heroicons/vue/outline";

import DropDown, {
  DropdownOrigin,
} from "@/components/common/dropdown/Dropdown.vue";
import DropdownItem from "@/components/common/dropdown/DropdownItem.vue";
import NavigationTabContainer from "@/components/common/navigation/NavigationTabContainer.vue";
import NavigationTab from "@/components/common/navigation/NavigationTab.vue";
import { RouteNames } from "@/router/routeNames";
import { BadgeType } from "@/components/common/badge/BadgeProps";
import { useStore } from "vuex";
import { RootState } from "@/store";
import dictionary from "@/dictionary";
import { Culture } from "@/enums";
import { ArrowNarrowLeftIcon } from "@heroicons/vue/solid";
import { notify } from "@kyvg/vue3-notification";
import { NotificationType } from "@/components/common/notification/Notification.types";
import { ILogger } from "@/plugins/logger";
import AccountForm from "./account/AccountForm.vue";

export default defineComponent({
  components: {
    Loader,
    Error,
    PageHeading,
    Section,
    DropDown,
    DropdownItem,
    NavigationTab,
    NavigationTabContainer,
    RouterLinkAuthWithIcon,
    AccountForm,
    Modal,
    Button,
  },
  setup() {
    // Logger
    const logger = inject<ILogger>("logger");
    if (!logger) throw "Missing logger";

    // Store and router setup
    const store = useStore<RootState>();
    const router = useRouter();

    const route = useRoute();
    const routeParamId = (route.params["id"] as string) || null;

    // Translations
    const texts = dictionary[store.getters["cultureStore/active"] as Culture];

    const loading = ref<boolean>(true);
    const actionLoading = ref<boolean>(false);
    const error = ref<boolean>(false);
    const account = ref<Account | undefined>(undefined);

    const matchesUser = store.getters["authStore/userMatchesAccount"] as (
      account: Account
    ) => boolean;

    // getAccount API call
    const getAccount = (accountId: string) => {
      return getAcountApiCall(accountId)
        .then((response) => {
          return new Account(response.data);
        })
        .catch((e) => {
          error.value = true;
          throw e;
        });
    };

    if (routeParamId) {
      getAccount(routeParamId)
        .then((acc) => {
          account.value = acc;
        })
        .catch((e) => logger.error(e))
        .finally(() => {
          loading.value = false;
        });
    }

    // (Un)block account feature
    const blockModalVisible = ref<boolean>(false);

    const toggleBlockModal = () => {
      blockModalVisible.value = !blockModalVisible.value;
    };

    const toggleBlock = (acc: Account) => {
      actionLoading.value = true;

      const blockPromise = acc.isBlocked
        ? unblockAccount(acc.id)
        : blockAccount(acc.id);

      return blockPromise
        .then(() => {
          notify({
            title: texts.navigationItems.account.edit.success.title,
            text: texts.navigationItems.account.edit.success.content,
            type: NotificationType.success,
          });
        })
        .catch((err) => {
          notify({
            title: texts.navigationItems.account.edit.failure.title,
            text: texts.navigationItems.account.edit.failure.content,
            type: NotificationType.failure,
          });

          throw err;
        })
        .then(() => getAccount(acc.id))
        .then((acc) => {
          account.value = acc;
        })
        .then(() => toggleBlockModal())
        .catch((e) => logger.error(e))
        .finally(() => {
          actionLoading.value = false;
        });
    };

    // Delete account feature
    const deleteModalVisible = ref<boolean>(false);

    const toggleDeleteModal = () => {
      deleteModalVisible.value = !deleteModalVisible.value;
    };

    const deleteAccount = (acc: Account) => {
      actionLoading.value = true;

      return deleteAccountApiCall(acc.id)
        .then(() => {
          notify({
            title: texts.navigationItems.account.delete.success.title,
            text: texts.navigationItems.account.delete.success.content,
            type: NotificationType.success,
          });

          router.push({ name: RouteNames.ACCOUNTS });
        })
        .catch((e) => {
          notify({
            title: texts.navigationItems.account.delete.failure.title,
            text: texts.navigationItems.account.delete.failure.content,
            type: NotificationType.failure,
          });

          logger.error(e);
        })
        .finally(() => {
          actionLoading.value = false;
        });
    };

    return {
      texts,
      actionLoading,
      loading,
      error,
      account,
      RouteNames,
      BadgeType,
      DropdownOrigin,
      toggleBlock,
      toggleBlockModal,
      blockModalVisible,
      deleteAccount,
      toggleDeleteModal,
      deleteModalVisible,
      ButtonType,
      ExclamationIcon,
      ModalType,
      BanIcon,
      BadgeCheckIcon,
      ArrowNarrowLeftIcon,
      TrashIcon,
      matchesUser,
    };
  },
});
</script>
