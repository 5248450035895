<template>
  <div class="border-b border-gray-200" data-testid="navigation-tabs">
    <nav class="-mb-px flex space-x-8" aria-label="Tabs">
      <slot></slot>
    </nav>
  </div>
</template>

<script lang="ts">
import { defineComponent } from "vue";

export default defineComponent({
  name: "NavigationTabContainer",
});
</script>
