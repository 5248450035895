
import dictionary from "@/dictionary";
import { Culture } from "@/enums";
import { RootState } from "@/store";
import { defineComponent } from "vue";
import { useStore } from "vuex";

export default defineComponent({
  name: "Unauthorized",
  setup() {
    const store = useStore<RootState>();
    const texts = dictionary[store.getters["cultureStore/active"] as Culture];

    return { texts };
  },
});
