import {
  AccountDTO,
  AccountCreateDTO,
  AccountUpdateDTO,
} from "@/models/account";
import { HostLoginDTO } from "@/models/hostLogin";
import HttpClient from "@/utils/httpClient";
import { AxiosResponse } from "axios";

const getAllAccounts = async (): Promise<AxiosResponse<AccountDTO[]>> => {
  return await HttpClient.instance.get<AccountDTO[]>("accounts");
};

const getAccount = async (
  accountId: string
): Promise<AxiosResponse<AccountDTO>> => {
  return await HttpClient.instance.get<AccountDTO>(`accounts/${accountId}`);
};

const createAccount = async (
  account: AccountCreateDTO
): Promise<AxiosResponse<void>> => {
  return await HttpClient.instance.post(`accounts/`, account);
};

const putAccount = async (
  account: AccountUpdateDTO
): Promise<AxiosResponse<void>> => {
  return await HttpClient.instance.put(`accounts/${account.id}`, account);
};

const blockAccount = async (
  accountId: string
): Promise<AxiosResponse<void>> => {
  return await HttpClient.instance.post<void>(`accounts/${accountId}/block`);
};

const unblockAccount = async (
  accountId: string
): Promise<AxiosResponse<void>> => {
  return await HttpClient.instance.post<void>(`accounts/${accountId}/unblock`);
};

const deleteAccount = async (
  accountId: string
): Promise<AxiosResponse<void>> => {
  return await HttpClient.instance.delete<void>(`accounts/${accountId}`);
};

const getAllHostLogins = async (): Promise<AxiosResponse<HostLoginDTO[]>> => {
  return await HttpClient.instance.get<HostLoginDTO[]>("hostlogins");
};

export {
  getAllAccounts,
  getAccount,
  createAccount,
  putAccount,
  blockAccount,
  unblockAccount,
  deleteAccount,
  getAllHostLogins,
};
