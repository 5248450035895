import { RouteNames } from "@/router/routeNames";
import store from "@/store";
import {
  NavigationGuard,
  NavigationGuardNext,
  RouteLocationNormalized,
} from "vue-router";

const tenantGuard: NavigationGuard = (
  to: RouteLocationNormalized,
  from: RouteLocationNormalized,
  next: NavigationGuardNext
) => {
  const tenant = store.getters["authStore/tenant"];
  const doesNotRequireTenant = to.meta["doesNotRequireTenant"] as
    | boolean
    | undefined;

  const errorLoadingTenant = store.getters["authStore/errorLoadingTenant"];

  if (!doesNotRequireTenant && !tenant && !errorLoadingTenant) {
    return next({ name: RouteNames.TENANTS });
  } else {
    return next();
  }
};

export { tenantGuard };
