import { createRouter, createWebHistory, RouteRecordRaw } from "vue-router";

import NotFound from "@/views/NotFound.vue";
import Accounts from "@/views/Accounts.vue";
import AccountEdit from "@/views/AccountEdit.vue";
import AccountCreate from "@/views/AccountCreate.vue";

import { RouteNames } from "./routeNames";
import { authenticationGuard } from "@/router/guards/authenticationGuard";
import { tenantGuard } from "@/router/guards/tenantGuard";
import { unauthorizedSurfConextGuard } from "@/router/guards/unauthorizedSurfConextGuard";
import { authorizationGuard } from "@/router/guards/authorizationGuard";

import Unauthorized from "@/views/Unauthorized.vue";
import { Permission } from "@/store/modules/auth.store";
import Tenant from "@/views/Tenant.vue";

const routes: Array<RouteRecordRaw> = [
  {
    path: "/",
    redirect: { name: RouteNames.ACCOUNTS },
  },
  {
    path: "/manage/accounts",
    name: RouteNames.ACCOUNTS,
    component: Accounts,
    meta: {
      permissions: [
        Permission.UserManagementRead,
        Permission.UserManagementWrite,
      ],
    },
  },
  {
    path: "/manage/accounts/create",
    name: RouteNames.ACCOUNT_CREATE,
    component: AccountCreate,
    meta: {
      permissions: [
        Permission.UserManagementRead,
        Permission.UserManagementWrite,
      ],
    },
  },
  {
    path: "/manage/accounts/edit/:id",
    name: RouteNames.ACCOUNT,
    component: AccountEdit,
    meta: {
      permissions: [
        Permission.UserManagementRead,
        Permission.UserManagementWrite,
      ],
    },
  },
  {
    path: "/tenants",
    name: RouteNames.TENANTS,
    components: { "full-screen": Tenant },
    meta: {
      permissions: [Permission.TenantsRead],
      doesNotRequireTenant: true,
    },
  },
  {
    path: "/unauthorized",
    name: RouteNames.UNAUTHORIZED,
    components: { "full-screen": Unauthorized },
    meta: {
      doesNotRequireTenant: true,
    },
  },
  {
    path: "/surf-conext-unauthorized",
    name: RouteNames.SURF_CONEXT_UNAUTHORIZED,
    components: { "full-screen": Unauthorized },
    meta: {
      doesNotRequireTenant: true,
      doesNotRequireAuthentication: true,
    },
  },
  {
    path: "/:pathMatch(.*)",
    name: RouteNames.NOT_FOUND,
    component: NotFound,
  },
];

const router = createRouter({
  history: createWebHistory(process.env.BASE_URL),
  routes,
});

router.beforeEach((to, from, next) => {
  return authenticationGuard(to, from, next);
});

router.beforeEach((to, from, next) => {
  return unauthorizedSurfConextGuard(to, from, next);
});

router.beforeEach((to, from, next) => {
  return authorizationGuard(to, from, next);
});

router.beforeEach((to, from, next) => {
  return tenantGuard(to, from, next);
});

export default router;
