
import {
  Dialog,
  DialogOverlay,
  DialogTitle,
  TransitionChild,
  TransitionRoot,
} from "@headlessui/vue";

import { computed, defineComponent, PropType } from "vue";
import { ModalType } from "@/components/common/modal/Modal.types";

export default defineComponent({
  name: "modal",
  components: {
    Dialog,
    DialogOverlay,
    DialogTitle,
    TransitionChild,
    TransitionRoot,
  },
  props: {
    visible: {
      type: Boolean as PropType<boolean>,
      required: true,
      default: false,
    },
    type: {
      type: String as PropType<ModalType>,
      required: true,
    },
    icon: {
      type: Function,
    },
    title: {
      type: String as PropType<string>,
      required: true,
    },
    description: {
      type: String as PropType<string>,
      required: true,
    },
  },
  setup(props, { emit }) {
    const visibleInternal = computed({
      get: () => props.visible,
      set: (value) => emit("update:visible", value),
    });

    const iconOuterClass = computed(() => [
      "mx-auto flex-shrink-0 flex items-center justify-center h-12 w-12 rounded-full sm:mx-0 sm:h-10 sm:w-10",
      {
        "bg-red-100": props.type === ModalType.danger,
        "bg-yellow-100": props.type === ModalType.warning,
      },
    ]);
    const iconInnerClass = computed(() => [
      "h-6 w-6",
      {
        "text-red-600": props.type === ModalType.danger,
        "text-yellow-600": props.type === ModalType.warning,
      },
    ]);

    return {
      visibleInternal,
      iconOuterClass,
      iconInnerClass,
      ModalType,
    };
  },
});
