<template>
  <span :class="classes" data-testid="badge-label">
    {{ label }}
  </span>
</template>

<script lang="ts">
import { defineComponent, PropType, computed } from "vue";
import { BadgeSize, BadgeType } from "./BadgeProps";

export default defineComponent({
  name: "Badge",
  props: {
    label: {
      type: String,
      required: true,
    },
    size: {
      type: String as PropType<BadgeSize>,
      required: false,
      default: BadgeSize.normal,
    },
    type: {
      type: String as PropType<BadgeType>,
      required: false,
      default: BadgeType.default,
    },
  },
  setup(props) {
    const classes = computed(() => [
      "inline-flex items-center py-0.5 rounded font-medium",
      {
        "px-2.5 text-xs": props.size === BadgeSize.normal,
        "px-3 text-sm": props.size === BadgeSize.large,
      },
      {
        "bg-gray-100 text-gray-800": props.type === BadgeType.default,
        "bg-brightgreen-100 text-brightgreen-800":
          props.type === BadgeType.success,
        "bg-yellow-100 text-yellow-800": props.type === BadgeType.warning,
        "bg-red-100 text-red-800": props.type === BadgeType.danger,
        "bg-blue-100 text-blue-800": props.type === BadgeType.info,
      },
    ]);
    return { classes };
  },
});
</script>
