<template>
  <RouterLinkAuth
    class="inline-flex items-center text-sm font-medium text-gray-500 hover:text-gray-700"
  >
    <component
      :is="icon"
      :class="[
        'h-5 w-5 text-gray-400',
        { 'mr-3': iconAlignment === IconAlignment.Left },
        { 'ml-3 order-last': iconAlignment === IconAlignment.Right },
      ]"
      aria-hidden="true"
    />
    {{ text }}
  </RouterLinkAuth>
</template>

<script lang="ts">
import { Component, defineComponent, PropType } from "vue";
import RouterLinkAuth from "@/components/common/router-link-auth/RouterLinkAuth.vue";
import { RouterLinkProps } from "vue-router";

export enum IconAlignment {
  Left = "left",
  Right = "right",
}

export interface RouterLinkWithIconProps extends RouterLinkProps {
  text: string;
  icon: Component;
  iconAlignment: IconAlignment;
}

export default defineComponent({
  name: "RouterLinkWithIcon",
  components: {
    RouterLinkAuth,
  },
  props: {
    text: {
      type: String,
      required: true,
    },
    icon: {
      type: Function,
      required: true,
    },
    iconAlignment: {
      type: String as PropType<IconAlignment>,
      required: false,
      default: IconAlignment.Left,
    },
  },
  setup() {
    return { IconAlignment };
  },
});
</script>
