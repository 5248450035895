<template>
  <div class="mt-8" v-if="userCanBeRedirectedToOldDashboard">
    <h3
      class="px-3 text-xs font-semibold text-gray-500 uppercase tracking-wider"
      id="other-nav-items-headline"
    >
      {{ texts.navigationItems.otherNavigation.title }}
    </h3>

    <div class="mt-1 space-y-1" aria-labelledby="other-nav-items-headline">
      <button
        class="group flex items-center px-3 py-2 text-sm font-medium text-gray-600 rounded-md hover:text-gray-900 hover:bg-gray-50"
        @click="redirectUserToOldDashboard"
      >
        <span class="truncate">
          {{ texts.navigationItems.otherNavigation.toOldDashboardLabel }}
        </span>
      </button>
    </div>
  </div>
</template>

<script lang="ts">
import dictionary from "@/dictionary";
import { Culture } from "@/enums";
import { redirectToOldDashboard } from "@/router/helpers/redirectToOldDashboard";
import { RootState } from "@/store";
import { computed, defineComponent } from "vue";
import { useStore } from "vuex";

export default defineComponent({
  name: "OtherNavigation",
  setup() {
    const store = useStore<RootState>();

    const texts = dictionary[store.getters["cultureStore/active"] as Culture];
    // A user can be redirected if the hostLoginId is set
    const userCanBeRedirectedToOldDashboard = computed<boolean>(
      () => store.getters["authStore/hostLoginId"]
    );

    const tenant = computed(() => store.getters["authStore/tenant"]);
    const token = computed(() => store.getters["authStore/token"]);

    const redirectUserToOldDashboard = () => {
      store.commit("authStore/setRedirectingToOldDashboard", true);
      redirectToOldDashboard(tenant.value.name, token.value);
    };

    return {
      texts,
      redirectUserToOldDashboard,
      userCanBeRedirectedToOldDashboard,
    };
  },
});
</script>
