<template>
  <notifications
    class="pt-4 max-w-md"
    width="100%"
    :duration="5000"
    :speed="500"
  >
    <template v-slot:body="{ item, close }">
      <Notification
        :type="item.type"
        :title="item.title"
        :content="item.text"
        :close="close"
      />
    </template>
  </notifications>

  <!-- Entire application is hidden when not authenticated because when unauthorised, only the router-view content is protected -->
  <div v-show="showApplication">
    <div v-if="redirectingToOldDashboard" class="flex flex-col items-center">
      <Loader></Loader>
      <div class="mt-2">{{ texts.isRedirecting.description }}</div>
    </div>

    <Error v-show="errorLoadingTenant"></Error>

    <ApplicationShell v-show="renderInApplicationShell">
      <router-view />
    </ApplicationShell>

    <!-- Loosely translated from https://tailwindui.com/components/application-ui/layout/containers#component-fa14d2deae17e4c14964ef86ea0a1603 -->
    <div
      class="max-w-7xl mx-auto sm:px-6 lg:px-8 py-6"
      v-show="renderAsFullScreen"
    >
      <router-view name="full-screen" />
    </div>
  </div>
</template>

<script lang="ts">
import { computed, defineComponent } from "vue";
import ApplicationShell from "@/components/application-shell/ApplicationShell.vue";
import { useStore } from "vuex";
import { RootState } from "./store";
import { useRouter } from "vue-router";
import Notification from "@/components/common/notification/Notification.vue";
import Error from "@/components/common/error/Error.vue";
import Loader from "./components/common/loader/Loader.vue";
import dictionary from "./dictionary";
import { Culture } from "./enums";

export default defineComponent({
  name: "App",
  components: {
    ApplicationShell,
    Notification,
    Error,
    Loader,
  },
  setup() {
    const store = useStore<RootState>();
    const router = useRouter();

    const texts = dictionary[store.getters["cultureStore/active"] as Culture];

    const errorLoadingTenant = computed(
      () => store.getters["authStore/errorLoadingTenant"]
    );

    const isAuthenticated = computed(
      () => store.getters["authStore/isAuthenticated"]
    );

    const isUnauthorizedSurfConextUser = computed(
      () => store.getters["authStore/isUnauthorizedSurfConextUser"]
    );

    const redirectingToOldDashboard = computed(
      () => store.getters["authStore/redirectingToOldDashboard"]
    );

    const isFullScreenPage = computed(() => {
      return router.currentRoute.value.matched.some((matched) =>
        Object.prototype.hasOwnProperty.call(matched.components, "full-screen")
      );
    });

    const renderInApplicationShell = computed(() => {
      return (
        !isFullScreenPage.value &&
        !errorLoadingTenant.value &&
        !redirectingToOldDashboard.value
      );
    });

    const renderAsFullScreen = computed(() => {
      return (
        isFullScreenPage.value &&
        !errorLoadingTenant.value &&
        !redirectingToOldDashboard.value
      );
    });

    const showApplication = computed(
      () => isAuthenticated.value || isUnauthorizedSurfConextUser.value
    );

    return {
      showApplication,
      errorLoadingTenant,
      redirectingToOldDashboard,
      renderAsFullScreen,
      renderInApplicationShell,
      texts,
    };
  },
});
</script>
