interface TenantDTO {
  name: string;
  uri: string;
}

class Tenant {
  name: string;
  uri: string;

  constructor(dto: TenantDTO) {
    this.name = dto.name;
    this.uri = dto.uri;
  }
}

export { Tenant, TenantDTO };
