interface HostLoginDTO {
  id: string;
  displayName: string;
  authKey: string | null;
}

class HostLogin {
  id: string;
  displayName: string;
  isDisabled: boolean;

  constructor(dto: HostLoginDTO) {
    this.id = dto.id;
    this.displayName = dto.displayName;
    this.isDisabled = dto.authKey != null;
  }
}

export { HostLoginDTO, HostLogin };
