
import { defineComponent, PropType } from "@vue/runtime-core";

import TextInput from "./TextInput.vue";
import { useField } from "vee-validate";
import { TextInputType } from "./TextInput.types";

export default defineComponent({
  props: {
    formFieldId: {
      type: String as PropType<string>,
      required: true,
    },
    type: {
      type: String as PropType<TextInputType>,
      default: TextInputType.TEXT,
    },
    label: String as PropType<string>,
    placeholder: String as PropType<string>,
    disabled: Boolean as PropType<boolean>,
  },
  components: {
    TextInput,
  },
  setup(props) {
    const { errorMessage, value } = useField<string | undefined>(
      props.formFieldId
    );
    return {
      errorMessage,
      value,
    };
  },
});
