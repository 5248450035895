import { login } from "@/plugins/authentication";
import store from "@/store";
import { computed, watchEffect } from "vue";
import {
  NavigationGuard,
  NavigationGuardNext,
  RouteLocationNormalized,
} from "vue-router";

const loading = computed(() => store.getters["authStore/loading"]);

const authenticationGuard: NavigationGuard = (
  to: RouteLocationNormalized,
  from: RouteLocationNormalized,
  next: NavigationGuardNext
) => {
  const verify = async () => {
    // This line of code ensures we can run e2e tests with Cypress without authenticating
    // and will not be compiled to production by checking the NODE_ENV
    // before checking window.Cypress
    // eslint-disable-next-line @typescript-eslint/ban-ts-comment
    // @ts-ignore
    if (process.env.NODE_ENV === "development" && window.Cypress) {
      return store
        .dispatch("authStore/login", {
          isAuthenticated: true,
          tenantUri: "tenant",
          user: {},
        })
        .then(() => next());
    }

    // Don't check if the route does not require authentication
    const doesNotRequireAuthentication = to.meta[
      "doesNotRequireAuthentication"
    ] as boolean | undefined;
    if (doesNotRequireAuthentication) {
      return next();
    }

    // If the user is authenticated, continue with the route
    const isAuthenticated = store.getters["authStore/isAuthenticated"];
    if (isAuthenticated) {
      return next();
    }

    // If the user is not authenticated, has a flag saying he's a special kind of
    // unauthorised SurfConext user than move to the next route instead of rerouting to the login page
    // that will cause an infinite loop
    const isUnauthorizedSurfConextUser =
      store.getters["authStore/isUnauthorizedSurfConextUser"];
    if (!isAuthenticated && isUnauthorizedSurfConextUser) {
      return next();
    }

    // Otherwise, redirect to login screen
    await login(to.fullPath);
  };

  // If loading has already finished, check our auth state using `fn()`
  if (!loading.value) {
    return verify();
  }

  // Watch for the loading property to change before we check isAuthenticated
  watchEffect(() => {
    if (!loading.value) {
      return verify();
    }
  });
};

export { authenticationGuard };
