<template>
  <tr>
    <slot></slot>
  </tr>
</template>

<script lang="ts">
import { defineComponent } from "vue";

export default defineComponent({
  name: "TableRow",
});
</script>
