import createAuth0Client, { Auth0Client, User } from "@auth0/auth0-spa-js";
import { App, computed, Plugin } from "vue";

import store from "@/store";
import { ILoginResult } from "@/store/modules/auth.store";
import { redirectToOldDashboard } from "@/router/helpers/redirectToOldDashboard";

let client: Auth0Client;

const loading = computed({
  get: () => store.getters["authStore/loading"],
  set: (isLoading) => store.commit("authStore/setLoading", isLoading),
});

interface IAuthPlugin {
  logout: () => void;
}

interface IAppState {
  targetUrl: string;
}

const plugin: IAuthPlugin = {
  logout: () =>
    client.logout({
      returnTo: process.env.VUE_APP_AUTH0_LOGOUT_URL,
    }),
};

const login = (auth0CallbackUrl: string): Promise<void> => {
  const appState: IAppState = { targetUrl: auth0CallbackUrl };
  return client.loginWithRedirect({ appState });
};

interface AuthOptions {
  domain: string;
  clientId: string;
  redirectUri: string;
  audience: string;
  onRedirectCallback(appState?: IAppState): void;
}

const setupAuth = async (options: AuthOptions): Promise<Plugin> => {
  client = await createAuth0Client({
    domain: options.domain,
    client_id: options.clientId,
    audience: options.audience,
    redirect_uri: options.redirectUri,
  });

  try {
    // If the user is returning to the app after authentication
    if (
      window.location.search.includes("code=") &&
      window.location.search.includes("state=")
    ) {
      // handle the redirect and retrieve tokens
      const { appState } = await client.handleRedirectCallback();

      // Notify subscribers that the redirect callback has happened, passing the appState
      // (useful for retrieving any pre-authentication state)
      options.onRedirectCallback(appState);
    }

    // Initialize our internal authentication state
    const loginResult: ILoginResult = {
      isAuthenticated: false,
      user: null,
      token: null,
      tenantUri: null,
      permissions: [],
      redirectingToOldDashboard: false,
      hostLoginId: null,
      isUnauthorizedSurfConextUser: false,
    };

    if (
      window.location.search.includes(
        "error_description=SurfConextUserNotKnown"
      )
    ) {
      loginResult.isUnauthorizedSurfConextUser = true;
    }

    loginResult.isAuthenticated = await client.isAuthenticated();

    if (loginResult.isAuthenticated) {
      loginResult.user = (await client.getUser()) || null;
      loginResult.token = await client.getTokenSilently();

      const claims = await client.getIdTokenClaims();
      const namespace = "http://schemas.summit.nl/identity/claims";

      loginResult.tenantUri = claims[`${namespace}/tenant`];
      loginResult.permissions = claims[`${namespace}/permissions`];
      loginResult.redirectingToOldDashboard =
        claims[`${namespace}/redirecttoolddashboard`];
      loginResult.hostLoginId = claims[`${namespace}/hostloginid`];

      if (
        loginResult.tenantUri &&
        loginResult.token &&
        loginResult.redirectingToOldDashboard &&
        loginResult.hostLoginId
      ) {
        redirectToOldDashboard(loginResult.tenantUri, loginResult.token);
      }
    }

    // The await statement below is there because we need to wait for the login action to be finished!
    // Failing to do so, results in triggering the tenantGuard (after a succesful routeguard) which will fail if the tenant API call has not resolved yet.
    await store.dispatch("authStore/login", loginResult);
  } catch (e) {
    store.commit("authStore/setError", e);
  } finally {
    loading.value = false;
  }

  const authPlugin: Plugin = {
    install(app: App): void {
      app.provide("Auth", plugin);
    },
  };

  return authPlugin;
};

export { setupAuth, login, IAuthPlugin, User, IAppState };
