<template>
  <div :class="!divider && 'mb-5'">
    <div class="sm:flex sm:justify-between sm:items-baseline">
      <div class="sm:w-0 sm:flex-1">
        <h3
          data-testid="title"
          class="text-lg leading-6 font-medium text-gray-900"
        >
          {{ title }}
        </h3>
        <p
          v-if="description"
          data-testid="description"
          class="mt-2 max-w-4xl text-sm text-gray-500"
        >
          {{ description }}
        </p>
      </div>
      <slot></slot>
    </div>
    <SectionDivider v-if="divider" />
  </div>
</template>

<script lang="ts">
import { defineComponent } from "vue";
import SectionDivider from "./SectionDivider.vue";

export interface SectionHeadingProps {
  title: string;
  description?: string;
  divider?: boolean;
}

export default defineComponent({
  name: "SectionHeading",
  components: {
    SectionDivider,
  },
  props: {
    title: {
      type: String,
      required: true,
    },
    description: {
      type: String,
    },
    divider: {
      type: Boolean,
      default: true,
    },
  },
});
</script>
