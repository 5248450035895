import { GetterTree, ModuleTree, Store, StoreOptions } from "vuex";

import authStore, { AuthStore, AuthStoreState } from "./modules/auth.store";

import cultureStore, {
  CultureStore,
  CultureStoreState,
} from "./modules/culture.store";

import accountStore, {
  AccountStore,
  AccountStoreState,
} from "./modules/account.store";

export type RootState = {
  authStore: AuthStoreState;

  cultureStore: CultureStoreState;
  accountStore: AccountStoreState;
};

interface RootModules extends ModuleTree<RootState> {
  cultureStore: CultureStore;

  authStore: AuthStore;
  accountStore: AccountStore;
}

const allModules: RootModules = {
  authStore,
  cultureStore,
  accountStore,
};

type RootGetters = GetterTree<RootState, RootState>;

const getters: RootGetters = {};

export interface RootStoreConfig extends StoreOptions<RootState> {
  state?: RootState;
  modules: RootModules;
  getters: RootGetters;
}

const storeConfig: RootStoreConfig = {
  modules: allModules,
  getters,
};

const store = new Store<RootState>(storeConfig);

export default store;
export type RootStore = Store<RootState>;

export { storeConfig };
