import { createApp } from "vue";
import App from "./App.vue";
import router from "./router";
import store from "./store";
import "./assets/css/style.css";

import { InsightsLoggerPlugin } from "@/plugins/logger";
import { InsightsTrackerPlugin } from "@/plugins/tracker";
import { setupAuth } from "@/plugins/authentication";
import { Snippet } from "@microsoft/applicationinsights-web";
import notifications from "@kyvg/vue3-notification";

const connectionString = process.env.VUE_APP_INSIGHTS_CONNECTION_STRING;

const insightsConfig: Snippet = { config: { connectionString } };
const isDevMode = process.env.NODE_ENV === "development";

const logger = new InsightsLoggerPlugin(
  router,
  store,
  insightsConfig,
  isDevMode
);
const tracker = new InsightsTrackerPlugin(
  router,
  store,
  logger,
  insightsConfig,
  isDevMode
);

async function init() {
  const auth = await setupAuth({
    clientId: process.env.VUE_APP_AUTH0_CLIENT_KEY,
    domain: process.env.VUE_APP_AUTH0_DOMAIN,
    redirectUri: window.location.origin,
    audience: process.env.VUE_APP_AUTH0_AUDIENCE,
    onRedirectCallback: (appState) => {
      router.push(
        appState && appState.targetUrl
          ? appState.targetUrl
          : window.location.pathname
      );
    },
  });

  createApp(App)
    .use(store)
    .use(router)
    .use(logger)
    .use(tracker)
    .use(notifications)
    .use(auth)
    .mount("#app");
}

init();
