interface ButtonProps {
  label: string;
  type: ButtonType;
  size: ButtonSize;
  downloadUrl?: string;
  disabled?: boolean;
  loading?: boolean;
}

enum ButtonSize {
  xs = "xs",
  sm = "sm",
  md = "md",
  lg = "lg",
  xl = "xl",
}
enum ButtonType {
  primary = "primary",
  secondary = "secondary",
  white = "white",
  danger = "danger",
  warning = "warning",
}

export { ButtonProps, ButtonType, ButtonSize };
