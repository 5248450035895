import { Culture } from "@/enums";

const CULTURE_STORAGE_KEY = "summit-edu-culture";

const getCultureFromLocalStorage = (): string | null => {
  return window.localStorage.getItem(CULTURE_STORAGE_KEY);
};

const setCultureInLocalStorage = (culture: Culture): void => {
  window.localStorage.setItem(CULTURE_STORAGE_KEY, culture);
};

export { getCultureFromLocalStorage, setCultureInLocalStorage };
