import {
  ApplicationInsights,
  IExceptionTelemetry,
  SeverityLevel,
  Snippet,
} from "@microsoft/applicationinsights-web";
import { App } from "vue";
import { Router } from "vue-router";
import { Store } from "vuex";

export interface ILogger {
  error: (error: Error, properties?: Record<string, unknown>) => void;
  warn: (error: Error, properties?: Record<string, unknown>) => void;
  log: (message: string, properties?: Record<string, unknown>) => void;
}

export class InsightsLogger implements ILogger {
  appInsights: ApplicationInsights;
  constructor(insightsConfig: Snippet, private development: boolean = false) {
    this.appInsights = new ApplicationInsights(insightsConfig);
    this.appInsights.loadAppInsights();
  }

  error(error: Error, properties?: Record<string, unknown>): void {
    const exception: IExceptionTelemetry = {
      exception: error,
      severityLevel: SeverityLevel.Error,
      properties,
    };
    if (this.development) {
      console.error(error);
    } else {
      this.appInsights.trackException(exception);
      console.error(error);
    }
  }

  warn(error: Error, properties?: Record<string, unknown>): void {
    const exception: IExceptionTelemetry = {
      exception: error,
      severityLevel: SeverityLevel.Warning,
      properties,
    };
    if (this.development) {
      console.warn(error);
    } else {
      this.appInsights.trackException(exception);
    }
  }

  log(message: string, properties?: Record<string, unknown>): void {
    const exception: IExceptionTelemetry = {
      severityLevel: SeverityLevel.Information,
      properties: {
        message,
        ...properties,
      },
    };
    if (this.development) {
      console.log(message);
    } else {
      this.appInsights.trackException(exception);
    }
  }
}

export class InsightsLoggerPlugin {
  constructor(
    private router: Router,
    private store: Store<unknown>,
    insightsConfig: Snippet,
    development?: boolean
  ) {
    this.logger = new InsightsLogger(insightsConfig, development);
  }

  logger: InsightsLogger;

  install(app: App): void {
    app.config.errorHandler = (err, instance, info) => {
      this.logger.error(err as Error, {
        info,
        state: this.store.state,
        route: this.router.currentRoute.value.fullPath,
      });
    };

    app.config.warnHandler = (msg, instance, info) => {
      this.logger.warn(new Error(msg), {
        info,
        state: this.store.state,
        route: this.router.currentRoute.value.fullPath,
      });
    };

    // Errors in Promises are not catched by handler and need to be handled separately
    window.addEventListener("unhandledrejection", (event) => {
      event.promise.catch((err) => {
        this.logger.error(err as Error, {
          info: event.reason,
          state: this.store.state,
          route: this.router.currentRoute.value.fullPath,
        });
      });
    });

    app.provide("logger", this.logger);
  }
}
