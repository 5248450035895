<template>
  <Loader data-testid="loading" v-if="loadingPage"></Loader>
  <Error data-testid="error" v-if="errorLoadingData"></Error>

  <div data-testid="main" v-if="!loadingPage && !errorLoadingData">
    <PageHeading :title="texts.navigationItems.accounts.title" :icon="KeyIcon">
      <template v-slot:description>
        {{ texts.navigationItems.accounts.description }}
      </template>
      <template v-slot:actions>
        <Button
          :label="texts.navigationItems.accounts.newAccountBtn"
          @click="goToCreateAccountPage"
        ></Button>
      </template>
    </PageHeading>

    <div class="grid grid-cols-4 gap-4 mb-5 items-center">
      <div>
        <Search v-model="search" :placeholder="texts.actions.search" />
      </div>
      <div></div>
      <div></div>
      <div>
        <div class="flex justify-end">
          <div class="flex items-center">
            <Checkbox
              id="blocked-accounts"
              data-testid="blocked-accounts"
              v-model="includeBlockedAccounts"
            />
          </div>
          <div class="ml-3 text-sm font-medium text-gray-500">
            <label for="blocked-accounts">{{
              texts.navigationItems.accounts.includeBlockedAccounts
            }}</label>
          </div>
        </div>
      </div>
    </div>

    <Table>
      <template v-slot:header>
        <TableRow>
          <TableHeader>{{
            texts.navigationItems.accounts.accountTable.headers.account
          }}</TableHeader>
          <TableHeader>{{
            texts.navigationItems.accounts.accountTable.headers.role
          }}</TableHeader>
          <TableHeader>{{
            texts.navigationItems.accounts.accountTable.headers.accountType
          }}</TableHeader>
          <TableHeader>{{
            texts.navigationItems.accounts.accountTable.headers.status
          }}</TableHeader>
          <TableHeader></TableHeader>
        </TableRow>
      </template>
      <TableRow
        v-for="account in accounts"
        :key="account.id"
        data-testid="account-row"
      >
        <TableColumn accent
          ><div class="flex items-center">
            <div class="flex-shrink-0 h-10 w-10">
              <img
                class="h-10 w-10 rounded-full"
                :src="account.picture"
                alt=""
              />
            </div>
            <div class="ml-4">
              <div
                class="text-sm font-medium text-gray-900"
                data-testid="account-name"
              >
                {{ account.name }}
              </div>
              <div class="text-sm text-gray-500" data-testid="account-email">
                {{ account.email }}
              </div>
            </div>
          </div>
        </TableColumn>
        <TableColumn>{{ account.roles.join("; ") }}</TableColumn>
        <TableColumn>{{ account.getTypeLabel(texts) }}</TableColumn>
        <TableColumn
          ><Badge
            data-testid="is-active-badge"
            v-if="!account.isBlocked"
            :label="texts.status.active"
            :type="BadgeType.success"
          ></Badge>
          <Badge
            data-testid="is-blocked-badge"
            v-if="account.isBlocked"
            :label="texts.status.blocked"
            :type="BadgeType.danger"
          ></Badge
        ></TableColumn>
        <TableColumn>
          <RouterLinkAuth
            data-testid="link-to-details"
            :to="{
              name: RouteNames.ACCOUNT,
              params: { id: account.id },
            }"
            class="text-brightgreen-500 hover:text-brightgreen-700"
          >
            {{ texts.navigationItems.accounts.accountTable.viewDetails }}
          </RouterLinkAuth>
        </TableColumn>
      </TableRow>
    </Table>
  </div>
</template>

<script lang="ts">
import { defineComponent, computed } from "vue";
import dictionary from "@/dictionary";
import { useStore } from "vuex";
import { RootState } from "@/store";
import { Culture } from "@/enums";
import { KeyIcon } from "@heroicons/vue/outline";
import Account from "@/models/account";

import Button from "@/components/common/button/Button.vue";
import Checkbox from "@/components/common/checkbox/Checkbox.vue";
import PageHeading from "@/components/common/page-heading/PageHeading.vue";
import Error from "@/components/common/error/Error.vue";
import Loader from "@/components/common/loader/Loader.vue";
import Table from "@/components/common/table/Table.vue";
import TableRow from "@/components/common/table/TableRow.vue";
import TableHeader from "@/components/common/table/TableHeader.vue";
import TableColumn from "@/components/common/table/TableColumn.vue";
import Badge from "@/components/common/badge/Badge.vue";
import { BadgeType } from "@/components/common/badge/BadgeProps";
import Search from "@/components/common/search/Search.vue";
import RouterLinkAuth from "@/components/common/router-link-auth/RouterLinkAuth.vue";
import { RouteNames } from "@/router/routeNames";
import router from "@/router";

export default defineComponent({
  components: {
    Loader,
    Error,
    Button,
    Badge,
    Checkbox,
    PageHeading,
    Table,
    TableRow,
    TableHeader,
    TableColumn,
    Search,
    RouterLinkAuth,
  },
  setup() {
    const store = useStore<RootState>();

    // Translations
    const texts = dictionary[store.getters["cultureStore/active"] as Culture];

    // Accounts data
    store.dispatch("accountStore/loadAccounts");

    const accounts = computed<Account[]>(() =>
      store.getters["accountStore/accounts"].filter(
        (account: Account) =>
          (search.value == null ||
            account.name.toLowerCase().indexOf(search.value.toLowerCase()) >=
              0 ||
            account.email.toLowerCase().indexOf(search.value.toLowerCase()) >=
              0) &&
          (includeBlockedAccounts.value || !account.isBlocked)
      )
    );

    const loadingAccounts = computed<boolean>(
      () => store.getters["accountStore/loadingAccounts"]
    );

    const errorLoadingAccounts = computed<boolean>(
      () => store.getters["accountStore/errorLoadingAccounts"]
    );

    const goToCreateAccountPage = () => {
      router.push({ name: RouteNames.ACCOUNT_CREATE });
    };

    // Loading
    const loadingPage = computed<boolean>(() => loadingAccounts.value);

    // Errors
    const errorLoadingData = computed<boolean>(
      () => errorLoadingAccounts.value
    );

    // Filters
    const search = computed<string | undefined>({
      get: () => store.getters["accountStore/search"] || undefined,
      set: (val) => {
        store.commit("accountStore/setSearch", val || null);
      },
    });

    const includeBlockedAccounts = computed<boolean>({
      get: () => store.getters["accountStore/includeBlockedAccounts"],
      set: (val) => {
        store.commit("accountStore/setIncludeBlockedAccounts", val);
      },
    });

    return {
      accounts,
      loadingPage,
      errorLoadingData,
      KeyIcon,
      BadgeType,
      texts,
      search,
      includeBlockedAccounts,
      RouteNames,
      goToCreateAccountPage,
    };
  },
});
</script>
