<template>
  <div
    class="grid grid-cols-1 gap-1 sm:grid-cols-3 sm:gap-x-4 sm:items-start mb-5"
  >
    <label :for="formFieldId" class="block text-sm font-medium text-gray-700">
      {{ label }}
    </label>
    <div class="mt-4 sm:mt-0">
      <div class="max-w-lg space-y-4">
        <div
          class="relative flex items-start"
          v-for="checkbox in items"
          :key="checkbox.value"
        >
          <div class="flex items-center h-5">
            <input
              type="checkbox"
              :name="formFieldId"
              :id="checkbox.id"
              :value="checkbox.value"
              v-model="checkedValues"
              class="focus:ring-brightgreen-500 h-4 w-4 text-brightgreen-600 border-gray-300 rounded"
            />
          </div>
          <div class="ml-3 text-sm">
            <label
              v-if="checkbox.label"
              :for="checkbox.id"
              class="font-medium text-gray-700"
            >
              {{ checkbox.label }}
            </label>
            <p v-if="checkbox.description" class="text-gray-500">
              {{ checkbox.description }}
            </p>
          </div>
        </div>
      </div>
    </div>
    <div
      v-if="errorMessage"
      class="text-red-500 text-sm sm:col-start-2"
      data-testid="error-message"
    >
      {{ errorMessage }}
    </div>
  </div>
</template>

<script lang="ts">
import { defineComponent, PropType } from "@vue/runtime-core";
import { useField } from "vee-validate";
import { computed } from "vue";

import { CheckboxFieldItem } from "./CheckboxField.types";

export default defineComponent({
  props: {
    formFieldId: {
      type: String as PropType<string>,
      required: true,
    },
    items: {
      type: Array as PropType<CheckboxFieldItem[]>,
      required: true,
    },
    label: String as PropType<string>,
  },
  setup(props) {
    const { value, errorMessage } = useField<string[] | undefined>(
      props.formFieldId
    );

    const checkedValues = computed<string[]>({
      get: () => value.value || [],
      set: (newValue) => {
        value.value = newValue.length > 0 ? newValue : undefined;
      },
    });

    return {
      checkedValues,
      errorMessage,
    };
  },
});
</script>
