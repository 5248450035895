
import { Component, defineComponent, PropType } from "vue";
import { MenuItem } from "@headlessui/vue";

export interface DropdownItemProps {
  label: string;
  icon?: Component;
  active: boolean;
}

export default defineComponent({
  name: "DropdownItem",
  props: {
    label: {
      type: String as PropType<string>,
      required: true,
    },
    icon: {
      type: Function,
    },
    active: {
      type: Boolean as PropType<boolean>,
      required: false,
      default: true,
    },
    disabled: {
      type: Boolean as PropType<boolean>,
      default: false,
    },
  },
  components: {
    MenuItem,
  },
});
