<template>
  <Loader data-testid="loading" v-if="loadingPage"></Loader>
  <Error data-testid="error" v-show="errorLoadingData"></Error>

  <div data-testid="main" v-show="!loadingPage && !errorLoadingData">
    <Section>
      <template v-slot:heading
        ><SectionHeading
          :title="texts.navigationItems.account.form.basicInformationTitle"
          :description="
            texts.navigationItems.account.form.basicInformationDescription
          "
          :divider="false"
        ></SectionHeading
      ></template>
      <SelectField
        formFieldId="accountType"
        :label="texts.navigationItems.account.form.accountTypeLabel"
        data-testid="accountType"
        :items="selectItems"
        :disabled="!!account"
      />
      <InputField
        formFieldId="username"
        :label="texts.navigationItems.account.form.usernameLabel"
        data-testid="username"
        :disabled="!!account"
      />
      <InputField
        formFieldId="name"
        :label="texts.navigationItems.account.form.nameLabel"
        data-testid="name"
      />
    </Section>
    <SectionDivider />
    <Section>
      <template v-slot:heading
        ><SectionHeading
          :title="texts.navigationItems.account.form.rolesTitle"
          :description="texts.navigationItems.account.form.rolesDescription"
          :divider="false"
        ></SectionHeading
      ></template>
      <CheckboxField
        formFieldId="roles"
        :label="texts.navigationItems.account.form.availableRolesLabel"
        :items="checkboxItems"
        data-testid="roles"
      />
    </Section>
    <SectionDivider />
    <Section>
      <template v-slot:heading
        ><SectionHeading
          :title="texts.navigationItems.account.form.otherSettingsTitle"
          :description="
            texts.navigationItems.account.form.otherSettingsDescription
          "
          :divider="false"
        ></SectionHeading
      ></template>

      <CheckboxField
        formFieldId="redirectToOldDashboardEnabled"
        :label="
          texts.navigationItems.account.form.redirectToOldDashboardEnabledLabel
        "
        :items="redirectItems"
        data-testid="redirectToOldDashboardEnabled"
      />

      <SelectField
        formFieldId="hostLoginId"
        :items="hostLoginItems"
        :label="texts.navigationItems.account.form.hostLoginIdLabel"
        data-testid="hostLoginId"
        :allowUnset="true"
      ></SelectField>
    </Section>
    <SectionDivider />

    <Section>
      <div class="flex justify-end">
        <Button
          :type="ButtonType.white"
          @click="cancelUpdate"
          :label="texts.actions.cancel"
          class="mr-4"
        ></Button>
        <Button
          data-testid="submitForm"
          :loading="actionLoading"
          @click="submitForm"
          :label="texts.actions.create"
        ></Button>
      </div>
    </Section>
  </div>
</template>

<script lang="ts">
import Loader from "@/components/common/loader/Loader.vue";
import Error from "@/components/common/error/Error.vue";
import Section from "@/components/common/section/Section.vue";
import SectionHeading from "@/components/common/section/SectionHeading.vue";
import SectionDivider from "@/components/common/section/SectionDivider.vue";

import InputField from "@/components/common/text-input/InputField.vue";
import SelectField from "@/components/common/select/SelectField.vue";
import CheckboxField from "@/components/common/checkbox/CheckboxField.vue";
import Button from "@/components/common/button/Button.vue";
import { ButtonType } from "@/components/common/button/Button.types";

import { PropType, defineComponent, ref, inject, computed } from "vue";
import Account, { AccountType, Role } from "@/models/account";
import { CheckboxFieldItem } from "@/components/common/checkbox/CheckboxField.types";
import { SelectOption } from "@/components/common/select/SelectOption";
import { ILogger } from "@/plugins/logger";

import { useForm } from "vee-validate";
import * as yup from "yup";
import {
  putAccount,
  createAccount as createAccountApiCall,
} from "@/services/account.service";
import { notify } from "@kyvg/vue3-notification";
import { NotificationType } from "@/components/common/notification/Notification.types";
import { useRouter } from "vue-router";
import { RouteNames } from "@/router/routeNames";
import { useStore } from "vuex";
import { RootState } from "@/store";
import dictionary from "@/dictionary";
import { Culture } from "@/enums";
import { AccountUpdateDTO, AccountCreateDTO } from "@/models/account";
import { HostLogin } from "@/models/hostLogin";

export default defineComponent({
  props: {
    account: {
      type: Object as PropType<Account>,
      required: false,
    },
  },
  components: {
    Loader,
    Error,
    Section,
    SectionHeading,
    SectionDivider,
    InputField,
    SelectField,
    CheckboxField,
    Button,
  },
  setup(props) {
    // Logger
    const logger = inject<ILogger>("logger");
    if (!logger) throw "Missing logger";

    // Store and router setup
    const store = useStore<RootState>();
    const router = useRouter();

    // Translations
    const texts = dictionary[store.getters["cultureStore/active"] as Culture];

    const actionLoading = ref<boolean>(false);

    const selectItems: Array<SelectOption> = [
      {
        value: AccountType.SUMMIT,
        label: texts.accountTypes.summitAccount,
      },
      {
        value: AccountType.SURFCONEXT,
        label: texts.accountTypes.surfConextAccount,
      },
    ];

    const checkboxItems: Array<CheckboxFieldItem> = [
      {
        id: Role.ADMINISTRATOR,
        value: Role.ADMINISTRATOR,
        label: texts.navigationItems.account.roles.administrator.title,
        description:
          texts.navigationItems.account.roles.administrator.description,
      },
      {
        id: Role.REDIRECTMEMBER,
        value: Role.REDIRECTMEMBER,
        label: texts.navigationItems.account.roles.redirectMember.title,
        description:
          texts.navigationItems.account.roles.redirectMember.description,
      },
    ];

    const redirectItems: Array<CheckboxFieldItem> = [
      {
        id: "redirectEnabled",
        value: "redirectEnabled",
        label:
          texts.navigationItems.account.form
            .redirectToOldDashboardEnabledCheckboxLabel,
        description:
          texts.navigationItems.account.form
            .redirectToOldDashboardEnabledCheckboxDescription,
      },
    ];

    // HostLogins data
    store.dispatch("accountStore/loadHostLogins");

    const loadingHostLogins = computed<boolean>(
      () => store.getters["accountStore/loadingHostLogins"]
    );

    const errorLoadingHostLogins = computed<boolean>(
      () => store.getters["accountStore/errorLoadingHostLogins"]
    );

    const hostLoginItems = computed<SelectOption[]>(() =>
      (store.getters["accountStore/hostLogins"] as HostLogin[]).map((hl) => ({
        label: hl.displayName,
        value: hl.id,
        disabled: hl.isDisabled,
      }))
    );

    const form = useForm({
      validationSchema: yup.object({
        accountType: yup.string().required(),
        username: yup.string().required().email().max(254),
        name: yup.string().required().max(32),
        roles: yup.array().of(yup.string().required()).required(),
        redirectToOldDashboardEnabled: yup.array().of(yup.string().required()),
        hostLoginId: yup.string().when("redirectToOldDashboardEnabled", {
          is: (formValue: string[] | undefined) =>
            (formValue || []).indexOf("redirectEnabled") >= 0,
          then: yup.string().required(),
          otherwise: yup.string(),
        }),
      }),
    });

    if (props.account) {
      form.setValues({
        accountType: props.account.accountType,
        username: props.account.email,
        name: props.account.name,
        roles: props.account.roles,
        redirectToOldDashboardEnabled: props.account
          .redirectToOldDashboardEnabled
          ? ["redirectEnabled"]
          : [],
        hostLoginId: props.account.hostLoginId?.toString(),
      });
    } else {
      form.setValues({
        accountType: AccountType.SUMMIT,
        redirectToOldDashboardEnabled: ["redirectEnabled"],
      });
    }

    const submitForm = () => {
      form.validate().then((result) => {
        if (!result.valid) {
          return;
        }

        if (props.account) {
          const updatedAccount: AccountUpdateDTO = {
            id: props.account.id,
            name: form.values.name as string,
            email: form.values.username as string,
            roles: form.values.roles as Role[],
            redirectToOldDashboardEnabled: !!form.values.redirectToOldDashboardEnabled?.find(
              (checkbox) => checkbox === "redirectEnabled"
            ),
            hostLoginId: form.values.hostLoginId || null,
          };

          updateAccount(updatedAccount);
        } else {
          const createdAccount: AccountCreateDTO = {
            name: form.values.name as string,
            email: form.values.username as string,
            roles: form.values.roles as Role[],
            accountType: form.values.accountType as AccountType,
            redirectToOldDashboardEnabled: !!form.values.redirectToOldDashboardEnabled?.find(
              (checkbox) => checkbox === "redirectEnabled"
            ),
            hostLoginId: form.values.hostLoginId || null,
          };

          createAccount(createdAccount);
        }
      });
    };

    const updateAccount = (updatedAccount: AccountUpdateDTO) => {
      actionLoading.value = true;

      return putAccount(updatedAccount)
        .then(() => {
          notify({
            title: texts.navigationItems.account.edit.success.title,
            text: texts.navigationItems.account.edit.success.content,
            type: NotificationType.success,
          });
        })
        .catch((e) => {
          notify({
            title: texts.navigationItems.account.edit.failure.title,
            text: texts.navigationItems.account.edit.failure.content,
            type: NotificationType.failure,
          });

          logger.error(e);
        })
        .finally(() => {
          actionLoading.value = false;
        });
    };

    const createAccount = (createdAccount: AccountCreateDTO) => {
      actionLoading.value = true;

      return createAccountApiCall(createdAccount)
        .then(() => {
          notify({
            title: texts.navigationItems.account.create.success.title,
            text: texts.navigationItems.account.create.success.content,
            type: NotificationType.success,
          });

          router.push({ name: RouteNames.ACCOUNTS });
        })
        .catch((e) => {
          notify({
            title: texts.navigationItems.account.create.failure.title,
            text: texts.navigationItems.account.create.failure.content,
            type: NotificationType.failure,
          });

          logger.error(e);
        })
        .finally(() => {
          actionLoading.value = false;
        });
    };

    const cancelUpdate = (): void => {
      router.push({ name: RouteNames.ACCOUNTS });
    };

    // Loading
    const loadingPage = computed<boolean>(() => loadingHostLogins.value);

    // Errors
    const errorLoadingData = computed<boolean>(
      () => errorLoadingHostLogins.value
    );

    return {
      form,
      selectItems,
      checkboxItems,
      redirectItems,
      hostLoginItems,
      submitForm,
      cancelUpdate,
      actionLoading,
      ButtonType,
      texts,
      loadingPage,
      errorLoadingData,
    };
  },
});
</script>
