
import { computed, defineComponent } from "vue";
import ApplicationShell from "@/components/application-shell/ApplicationShell.vue";
import { useStore } from "vuex";
import { RootState } from "./store";
import { useRouter } from "vue-router";
import Notification from "@/components/common/notification/Notification.vue";
import Error from "@/components/common/error/Error.vue";
import Loader from "./components/common/loader/Loader.vue";
import dictionary from "./dictionary";
import { Culture } from "./enums";

export default defineComponent({
  name: "App",
  components: {
    ApplicationShell,
    Notification,
    Error,
    Loader,
  },
  setup() {
    const store = useStore<RootState>();
    const router = useRouter();

    const texts = dictionary[store.getters["cultureStore/active"] as Culture];

    const errorLoadingTenant = computed(
      () => store.getters["authStore/errorLoadingTenant"]
    );

    const isAuthenticated = computed(
      () => store.getters["authStore/isAuthenticated"]
    );

    const isUnauthorizedSurfConextUser = computed(
      () => store.getters["authStore/isUnauthorizedSurfConextUser"]
    );

    const redirectingToOldDashboard = computed(
      () => store.getters["authStore/redirectingToOldDashboard"]
    );

    const isFullScreenPage = computed(() => {
      return router.currentRoute.value.matched.some((matched) =>
        Object.prototype.hasOwnProperty.call(matched.components, "full-screen")
      );
    });

    const renderInApplicationShell = computed(() => {
      return (
        !isFullScreenPage.value &&
        !errorLoadingTenant.value &&
        !redirectingToOldDashboard.value
      );
    });

    const renderAsFullScreen = computed(() => {
      return (
        isFullScreenPage.value &&
        !errorLoadingTenant.value &&
        !redirectingToOldDashboard.value
      );
    });

    const showApplication = computed(
      () => isAuthenticated.value || isUnauthorizedSurfConextUser.value
    );

    return {
      showApplication,
      errorLoadingTenant,
      redirectingToOldDashboard,
      renderAsFullScreen,
      renderInApplicationShell,
      texts,
    };
  },
});
