import { Culture } from "@/enums";

type Localizable<T> = Record<Culture, T>;

export interface ApplicationDictionary {
  isLoading: string;
  status: {
    active: string;
    archived: string;
    blocked: string;
  };
  actions: {
    actions: string;
    block: string;
    unblock: string;
    choice: string;
    cancel: string;
    delete: string;
    update: string;
    create: string;
    search: string;
    backToOverview: string;
  };
  unauthorized: {
    title: string;
    description: string;
  };
  isRedirecting: {
    description: string;
  };
  notFound: {
    title: string;
    description: string;
  };
  error: {
    title: string;
    description: string;
  };
  navigationItems: {
    dashboard: NavigationItemsDictionary;
    manage: { title: string };
    accounts: AccountsPageDictionary;
    account: AccountPageDictionary;
    otherNavigation: {
      title: string;
      toOldDashboardLabel: string;
    };
  };
  profileMenuItems: {
    signedInAs: string;
    switchTenant: string;
    signout: string;
  };
  culturePicker: Record<Culture, string>;
  validation: ValidationDictionary;
  accountTypes: AccountTypeDictionary;
}

interface NavigationItemsDictionary {
  title: string;
  description: string;
}

interface AccountsPageDictionary extends NavigationItemsDictionary {
  newAccountBtn: string;
  includeBlockedAccounts: string;
  accountTable: {
    headers: {
      account: string;
      status: string;
      role: string;
      accountType: string;
    };
    viewDetails: string;
  };
}

interface AccountPageDictionary {
  delete: {
    success: {
      title: string;
      content: string;
    };
    failure: {
      title: string;
      content: string;
    };
  };
  edit: {
    success: {
      title: string;
      content: string;
    };
    failure: {
      title: string;
      content: string;
    };
  };
  create: {
    title: string;
    success: {
      title: string;
      content: string;
    };
    failure: {
      title: string;
      content: string;
    };
  };
  navigationTabs: {
    details: string;
  };
  modal: {
    blockAccount: {
      title: string;
      description: string;
    };
    unblockAccount: {
      title: string;
      description: string;
    };
    deleteAccount: {
      title: string;
      description: string;
    };
  };
  form: {
    basicInformationTitle: string;
    basicInformationDescription: string;
    accountTypeLabel: string;
    usernameLabel: string;
    nameLabel: string;
    rolesTitle: string;
    rolesDescription: string;
    otherSettingsTitle: string;
    otherSettingsDescription: string;
    availableRolesLabel: string;
    redirectToOldDashboardEnabledCheckboxLabel: string;
    redirectToOldDashboardEnabledCheckboxDescription: string;
    redirectToOldDashboardEnabledLabel: string;
    hostLoginIdLabel: string;
  };
  roles: {
    administrator: {
      title: string;
      description: string;
    };
    redirectMember: {
      title: string;
      description: string;
    };
  };
}

interface ValidationDictionary {
  required: string;
  maxLength: string;
  email: string;
}

interface AccountTypeDictionary {
  summitAccount: string;
  surfConextAccount: string;
}

const accountTypeDictionary: AccountTypeDictionary = {
  summitAccount: "Summit",
  surfConextAccount: "SURFconext",
};

const dictionary: Localizable<ApplicationDictionary> = {
  "nl-NL": {
    isLoading: "Aan het laden...",
    status: {
      active: "Actief",
      archived: "Gearchiveerd",
      blocked: "Geblokkeerd",
    },
    actions: {
      actions: "Acties",
      block: "Blokkeer",
      unblock: "Deblokkeer",
      choice: "Maak uw keuze",
      cancel: "Annuleer",
      delete: "Verwijder",
      update: "Updaten",
      create: "Opslaan",
      search: "Zoeken...",
      backToOverview: "Terug naar overzicht",
    },
    unauthorized: {
      title: "Niet toegestaan",
      description:
        "Je hebt niet de juiste permissies om deze pagina te bekijken",
    },
    isRedirecting: {
      description: "Je wordt doorgestuurd naar het Summit Dashboard...",
    },
    notFound: {
      title: "Pagina niet gevonden",
      description: "Deze pagina kon niet gevonden worden.",
    },
    error: {
      title: "De pagina kon niet worden geladen",
      description: "Er is iets fout gegaan, probeer het later opnieuw.",
    },
    navigationItems: {
      dashboard: {
        title: "Dashboard",
        description:
          "Op deze pagina vind je een overzicht van alle zaken die momenteel de aandacht vereisen.",
      },
      manage: { title: "Beheren" },
      accounts: {
        title: "Accounts",
        description:
          "Hieronder een overzicht van alle actieve en geblokkeerde accounts voor het dashboard.",
        newAccountBtn: "Nieuw account aanmaken",
        includeBlockedAccounts: "Toon geblokkeerde items",
        accountTable: {
          headers: {
            account: "Naam",
            status: "Status",
            role: "Rol",
            accountType: "Accounttype",
          },
          viewDetails: "Bekijk",
        },
      },
      account: {
        delete: {
          success: {
            title: "Account verwijderen gelukt!",
            content: "Het account is succesvol verwijderd.",
          },
          failure: {
            title: "Account verwijderen mislukt",
            content:
              "Het verwijderen van het account is mislukt. Probeer het a.u.b. opnieuw.",
          },
        },
        edit: {
          success: {
            title: "Wijzigingen opgeslagen",
            content:
              "De wijzigingen die aangebracht zijn, zijn succesvol opgeslagen op het account.",
          },
          failure: {
            title: "Wijzigingen niet opgeslagen",
            content:
              "De wijzigingen die aangebracht zijn, zijn niet opgeslagen op het account. Probeer het opnieuw.",
          },
        },
        create: {
          title: "Nieuw account",

          success: {
            title: "Account opgeslagen",
            content: "Het zojuist aangemaakte account is succesvol opgeslagen",
          },
          failure: {
            title: "Account niet opgeslagen",
            content:
              "Het is niet gelukt een account te maken. Probeer het opnieuw.",
          },
        },
        navigationTabs: {
          details: "Accountgegevens",
        },
        modal: {
          blockAccount: {
            title: "Blokkeer account",
            description: "Weet u zekker dat u het account wilt blokkeren?",
          },
          unblockAccount: {
            title: "Deblokkeer account",
            description: "Weet u zekker dat u het account wilt deblokkeren?",
          },
          deleteAccount: {
            title: "Verwijder account",
            description: "Weet u zekker dat u het account wilt verwijderen?",
          },
        },
        form: {
          basicInformationTitle: "Basisgegevens",
          basicInformationDescription:
            "Basisgegevens die gekoppeld zijn aan het dashboard-account.",
          accountTypeLabel: "Accounttype",
          usernameLabel: "Gebruikersnaam (email)",
          nameLabel: "Naam",
          rolesTitle: "Rollen",
          rolesDescription:
            "Geef de rol(len) op die voor dit account van toepassing zijn",
          availableRolesLabel: "Beschikbare rollen",
          redirectToOldDashboardEnabledLabel: "Redirect instellen",
          redirectToOldDashboardEnabledCheckboxLabel:
            "Direct doorsturen naar huidige Summit Dashboard",
          redirectToOldDashboardEnabledCheckboxDescription:
            "Omdat het nieuwe dashboard nog niet alle functionaliteiten heeft",
          hostLoginIdLabel: "Koppelen aan account andere dashboard",
          otherSettingsTitle: "Overige instellingen",
          otherSettingsDescription:
            "Alle overige instellingen die ingevuld kunnen worden voor het account",
        },
        roles: {
          administrator: {
            title: "Administrator",
            description:
              "Deze rol mag alles binnen het dashboard zien en gebruiken.",
          },
          redirectMember: {
            title: "RedirectMember",
            description:
              "Deze rol mag alleen geredirect worden naar het oude dashboard",
          },
        },
      },
      otherNavigation: {
        title: "Overige opties",
        toOldDashboardLabel: "Redirect naar oude dashboard",
      },
    },
    profileMenuItems: {
      signedInAs: "Ingelogd als:",
      switchTenant: "Wissel klant",
      signout: "Uitloggen",
    },
    culturePicker: {
      "nl-NL": "Nederlands",
      "en-US": "English",
    },
    validation: {
      required: "is verplicht",
      maxLength: "heeft een maximum lengte van ${max}",
      email: "moet een geldig e-mailadres zijn",
    },
    accountTypes: accountTypeDictionary,
  },
  "en-US": {
    isLoading: "Loading...",
    status: {
      active: "Active",
      archived: "Archived",
      blocked: "Blocked",
    },
    actions: {
      actions: "Actions",
      block: "Block",
      unblock: "Unblock",
      choice: "Make your choice",
      cancel: "Cancel",
      delete: "Delete",
      update: "Update",
      create: "Save",
      search: "Search...",
      backToOverview: "Back to overview",
    },
    unauthorized: {
      title: "Unauthorized",
      description: "You don't have the permission to view this page.",
    },
    isRedirecting: {
      description: "You're being redirected to the Summit Dashboard...",
    },
    notFound: {
      title: "Page not found",
      description: "This page could not be found.",
    },
    error: {
      title: "Page could not be loaded",
      description: "Something went wrong, try again later",
    },
    navigationItems: {
      dashboard: {
        title: "Dashboard",
        description:
          "On this page you will find an overview of all matters that currently require attention.",
      },
      manage: { title: "Manage" },
      accounts: {
        title: "Accounts",
        description:
          "In the overview below you see all active and blocked accounts for the dashboard.",
        newAccountBtn: "Create new account",
        includeBlockedAccounts: "Show blocked items",
        accountTable: {
          headers: {
            account: "Name",
            status: "Status",
            role: "Role",
            accountType: "Account type",
          },
          viewDetails: "View",
        },
      },
      account: {
        delete: {
          success: {
            title: "Account deletion succesful!",
            content: "The account has been successfully deleted.",
          },
          failure: {
            title: "Account deletion failed",
            content: "Deleting the account has failed. Please try again.",
          },
        },
        edit: {
          success: {
            title: "Changes saved",
            content:
              "The changes you made to the account have been succesfully saved.",
          },
          failure: {
            title: "Changes could not be saved",
            content:
              "Changes you made to the account could not be saved. Please try again.",
          },
        },
        create: {
          title: "New account",

          success: {
            title: "Account created",
            content: "The account was succesfully created",
          },
          failure: {
            title: "Account could not be created",
            content: "The account could not be created. Please try again.",
          },
        },
        navigationTabs: {
          details: "Account details",
        },
        modal: {
          blockAccount: {
            title: "Block account",
            description: "Are you sure you want to block the account?",
          },
          unblockAccount: {
            title: "Unblock account",
            description: "Are you sure you want to unblock the account?",
          },
          deleteAccount: {
            title: "Delete account",
            description: "Are you sure you want to delete the account?",
          },
        },
        form: {
          basicInformationTitle: "Basic information",
          basicInformationDescription:
            "Basic information that is connected to the dashboard-account.",
          accountTypeLabel: "Accounttype",
          usernameLabel: "Username (email)",
          nameLabel: "Name",
          rolesTitle: "Roles",
          rolesDescription:
            "Select which roles are applicable for this account",
          availableRolesLabel: "Available roles",
          redirectToOldDashboardEnabledLabel: "Setup redirect",
          redirectToOldDashboardEnabledCheckboxLabel:
            "Direct user automatically to old dashboard",
          redirectToOldDashboardEnabledCheckboxDescription:
            "The new dashboard does not have all functionality",
          hostLoginIdLabel: "Connect to account on other dashboard",
          otherSettingsTitle: "Other settings",
          otherSettingsDescription:
            "All the other information that can be configured for an account",
        },
        roles: {
          administrator: {
            title: "Administrator",
            description: "This role can do anything in the dashboard",
          },
          redirectMember: {
            title: "Redirect Member",
            description:
              "This role can only be redirected to the old dashboard",
          },
        },
      },
      otherNavigation: {
        title: "Other options",
        toOldDashboardLabel: "Redirect to old dashboard",
      },
    },
    profileMenuItems: {
      signedInAs: "Signed in as:",
      switchTenant: "Switch tenant",
      signout: "Sign out",
    },
    culturePicker: {
      "nl-NL": "Nederlands",
      "en-US": "English",
    },
    validation: {
      required: "is mandatory",
      maxLength: "has a maximum length of ${max}",
      email: "needs to be a valid e-mail address",
    },
    accountTypes: accountTypeDictionary,
  },
};

export default dictionary;
