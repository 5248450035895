
import { defineComponent } from "vue";
import SectionDivider from "./SectionDivider.vue";

export interface SectionHeadingProps {
  title: string;
  description?: string;
  divider?: boolean;
}

export default defineComponent({
  name: "SectionHeading",
  components: {
    SectionDivider,
  },
  props: {
    title: {
      type: String,
      required: true,
    },
    description: {
      type: String,
    },
    divider: {
      type: Boolean,
      default: true,
    },
  },
});
