<template>
  <a
    :href="href"
    :class="[
      !dark &&
        (current
          ? 'bg-gray-200 text-gray-900'
          : 'text-gray-600 hover:bg-gray-50 hover:text-gray-900'),
      dark &&
        (current
          ? 'bg-gray-900 text-white'
          : 'text-gray-300 hover:bg-gray-700 hover:text-white'),
      'flex items-center px-3 py-2 text-sm font-medium rounded-md cursor-pointer',
    ]"
    :aria-current="current ? 'page' : undefined"
    data-testid="navigation-tab"
  >
    <div v-if="indent" class="flex-shrink-0 -ml-1 mr-3 h-6 w-6"></div>
    <component
      v-if="icon"
      :is="icon"
      :class="[
        !dark &&
          (current
            ? 'text-gray-500'
            : 'text-gray-400 group-hover:text-gray-500'),
        dark &&
          (current
            ? 'text-gray-300'
            : 'text-gray-400 group-hover:text-gray-300'),
        'flex-shrink-0 -ml-1 mr-3 h-6 w-6',
      ]"
      aria-hidden="true"
    />
    <span class="flex-1" data-testid="navigation-tab-name">{{ name }}</span>
    <span
      v-if="count"
      :class="[
        !dark && (current ? 'bg-white' : 'bg-gray-100 group-hover:bg-gray-200'),
        dark &&
          (current ? 'bg-gray-800' : 'bg-gray-900 group-hover:bg-gray-800'),
        'ml-3 inline-block py-0.5 px-3 text-xs rounded-full',
      ]"
    >
      {{ count }}
    </span>
    <svg
      v-if="expandedArrow !== undefined"
      :class="[
        !dark &&
          (current
            ? 'text-gray-400'
            : 'text-gray-300 group-hover:text-gray-400'),
        dark &&
          (current
            ? 'text-gray-200 '
            : 'text-gray-300 group-hover:text-gray-200'),
        expandedArrow && 'rotate-90',
        'ml-3 flex-shrink-0 h-5 w-5 transform  transition-colors ease-in-out duration-150',
      ]"
      viewBox="0 0 20 20"
      aria-hidden="true"
    >
      <path d="M6 6L14 10L6 14V6Z" fill="currentColor" />
    </svg>
  </a>
</template>

<script lang="ts">
import { defineComponent, PropType } from "vue";

export default defineComponent({
  name: "NavigationTabVertical",
  props: {
    name: {
      type: String as PropType<string>,
      required: true,
    },
    icon: {
      type: Function,
    },
    count: {
      type: Number as PropType<number>,
    },
    href: {
      type: String as PropType<string>,
    },
    current: {
      type: Boolean as PropType<boolean>,
    },
    dark: {
      type: Boolean as PropType<boolean>,
      default: false,
    },
    indent: {
      type: Boolean as PropType<boolean>,
      default: false,
    },
    expandedArrow: {
      type: Boolean as PropType<boolean>,
      default: undefined,
    },
  },
});
</script>
