<template>
  <div class="mb-5 md:flex md:items-start md:justify-between md:space-x-5">
    <div class="flex items-start space-x-5">
      <component
        v-if="!!icon"
        :is="icon"
        class="text-black flex-shrink-0 h-14 w-14"
        aria-hidden="true"
      />
      <div>
        <div class="flex items-center space-x-2">
          <h1
            class="text-2xl font-bold text-gray-900 flex items-center"
            data-testid="page-heading-title"
          >
            {{ title }}
          </h1>
          <Badge
            v-if="badgeLabel"
            :label="badgeLabel"
            :type="badgeType"
          ></Badge>
        </div>

        <p class="text-sm font-medium text-gray-500">
          <slot name="description"></slot>
        </p>
      </div>
    </div>
    <div
      class="mt-6 flex flex-col-reverse justify-stretch space-y-4 space-y-reverse sm:flex-row-reverse sm:justify-end sm:space-x-reverse sm:space-y-0 sm:space-x-3 md:mt-0 md:flex-row md:space-x-3"
    >
      <slot name="actions"></slot>
    </div>
  </div>
</template>

<script lang="ts">
import { defineComponent, PropType } from "vue";
import Badge from "@/components/common/badge/Badge.vue";
import { BadgeType } from "@/components/common/badge/BadgeProps";

export interface PageHeadingProps {
  title: string;
  icon?: Record<string, unknown>;
  badgeLabel?: string;
  badgeType?: BadgeType;
}

export default defineComponent({
  name: "PageHeading",
  components: {
    Badge,
  },
  props: {
    title: {
      type: String as PropType<string>,
      required: true,
    },
    icon: {
      type: Function,
    },
    badgeLabel: {
      type: String as PropType<string>,
    },
    badgeType: {
      type: String as PropType<BadgeType>,
    },
  },
});
</script>
