import { RouteNames } from "@/router/routeNames";
import store from "@/store";
import {
  NavigationGuard,
  NavigationGuardNext,
  RouteLocationNormalized,
} from "vue-router";

const unauthorizedSurfConextGuard: NavigationGuard = (
  to: RouteLocationNormalized,
  from: RouteLocationNormalized,
  next: NavigationGuardNext
) => {
  const isAuthenticated = store.getters["authStore/isAuthenticated"];
  const isUnauthorizedSurfConextUser =
    store.getters["authStore/isUnauthorizedSurfConextUser"];

  if (
    !isAuthenticated &&
    isUnauthorizedSurfConextUser &&
    to.name !== RouteNames.SURF_CONEXT_UNAUTHORIZED
  ) {
    return next({ name: RouteNames.SURF_CONEXT_UNAUTHORIZED });
  } else {
    return next();
  }
};

export { unauthorizedSurfConextGuard };
