import { ApplicationDictionary } from "@/dictionary";

interface AccountDTO {
  id: string;
  accountType: AccountType;
  email: string;
  name: string;
  roles: Role[];
  picture: string;
  isBlocked: boolean;
  redirectToOldDashboardEnabled: boolean;
  hostLoginId: string | null;
}

interface AccountCreateDTO {
  name: string;
  email: string;
  roles: Role[];
  accountType: AccountType;
  redirectToOldDashboardEnabled: boolean;
  hostLoginId: string | null;
}

interface AccountUpdateDTO {
  id: string;
  name: string;
  email: string;
  roles: Role[];
  redirectToOldDashboardEnabled: boolean;
  hostLoginId: string | null;
}

enum AccountType {
  SUMMIT = "SummitAccount",
  SURFCONEXT = "SurfConextAccount",
}

enum Role {
  ADMINISTRATOR = "Administrator",
  REDIRECTMEMBER = "RedirectMember",
}

class Account {
  id: string;
  accountType: AccountType;
  email: string;
  name: string;
  roles: Role[];
  picture: string;
  isBlocked: boolean;
  redirectToOldDashboardEnabled: boolean;
  hostLoginId: string | null;

  constructor(dto: AccountDTO) {
    this.id = dto.id;
    this.accountType = dto.accountType;
    this.email = dto.email;
    this.name = dto.name;
    this.roles = dto.roles;
    this.picture = dto.picture;
    this.isBlocked = dto.isBlocked;
    this.redirectToOldDashboardEnabled = dto.redirectToOldDashboardEnabled;
    this.hostLoginId = dto.hostLoginId;
  }

  clone(): Account {
    return new Account(this.toDto());
  }

  toDto(): AccountDTO {
    return {
      id: this.id,
      accountType: this.accountType,
      email: this.email,
      name: this.name,
      roles: this.roles,
      picture: this.picture,
      isBlocked: this.isBlocked,
      redirectToOldDashboardEnabled: this.redirectToOldDashboardEnabled,
      hostLoginId: this.hostLoginId,
    };
  }

  getTypeLabel(texts: ApplicationDictionary): string {
    switch (this.accountType) {
      case AccountType.SUMMIT: {
        return texts.accountTypes.summitAccount;
      }
      case AccountType.SURFCONEXT: {
        return texts.accountTypes.surfConextAccount;
      }
    }
  }
}

export default Account;
export { AccountDTO, AccountCreateDTO, AccountUpdateDTO, AccountType, Role };
