<!-- This example requires Tailwind CSS v2.0+ -->
<template>
  <TransitionRoot as="template" :show="visibleInternal">
    <Dialog
      as="div"
      static
      class="fixed z-10 inset-0 overflow-y-auto"
      @close="visibleInternal = false"
      :open="visibleInternal"
    >
      <div
        class="flex items-end justify-center min-h-screen pt-4 px-4 pb-20 text-center sm:block sm:p-0"
      >
        <TransitionChild
          as="template"
          enter="ease-out duration-300"
          enter-from="opacity-0"
          enter-to="opacity-100"
          leave="ease-in duration-200"
          leave-from="opacity-100"
          leave-to="opacity-0"
        >
          <DialogOverlay
            class="fixed inset-0 bg-gray-500 bg-opacity-75 transition-opacity"
          />
        </TransitionChild>

        <!-- This element is to trick the browser into centering the modal contents. -->
        <span
          class="hidden sm:inline-block sm:align-middle sm:h-screen"
          aria-hidden="true"
          >&#8203;</span
        >
        <TransitionChild
          as="template"
          enter="ease-out duration-300"
          enter-from="opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95"
          enter-to="opacity-100 translate-y-0 sm:scale-100"
          leave="ease-in duration-200"
          leave-from="opacity-100 translate-y-0 sm:scale-100"
          leave-to="opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95"
        >
          <div
            class="inline-block align-bottom bg-white rounded-lg px-4 pt-5 pb-4 text-left overflow-hidden shadow-xl transform transition-all sm:my-8 sm:align-middle sm:max-w-lg sm:w-full sm:p-6"
          >
            <div class="sm:flex sm:items-start sm:space-x-4">
              <div v-if="icon" :class="iconOuterClass">
                <component
                  :is="icon"
                  :class="iconInnerClass"
                  aria-hidden="true"
                />
              </div>
              <div class="mt-3 text-center sm:mt-0 sm:text-left">
                <DialogTitle
                  as="h3"
                  class="text-lg leading-6 font-medium text-gray-900"
                  data-testid="modal-title"
                >
                  {{ title }}
                </DialogTitle>
                <div class="mt-2">
                  <p class="text-sm text-gray-500">
                    {{ description }}
                  </p>
                </div>
              </div>
            </div>
            <slot name="buttons">
              <!-- Default button because Dialog-component requires a button element to focus-->
              <button></button>
            </slot>
          </div>
        </TransitionChild>
      </div>
    </Dialog>
  </TransitionRoot>
</template>

<script lang="ts">
import {
  Dialog,
  DialogOverlay,
  DialogTitle,
  TransitionChild,
  TransitionRoot,
} from "@headlessui/vue";

import { computed, defineComponent, PropType } from "vue";
import { ModalType } from "@/components/common/modal/Modal.types";

export default defineComponent({
  name: "modal",
  components: {
    Dialog,
    DialogOverlay,
    DialogTitle,
    TransitionChild,
    TransitionRoot,
  },
  props: {
    visible: {
      type: Boolean as PropType<boolean>,
      required: true,
      default: false,
    },
    type: {
      type: String as PropType<ModalType>,
      required: true,
    },
    icon: {
      type: Function,
    },
    title: {
      type: String as PropType<string>,
      required: true,
    },
    description: {
      type: String as PropType<string>,
      required: true,
    },
  },
  setup(props, { emit }) {
    const visibleInternal = computed({
      get: () => props.visible,
      set: (value) => emit("update:visible", value),
    });

    const iconOuterClass = computed(() => [
      "mx-auto flex-shrink-0 flex items-center justify-center h-12 w-12 rounded-full sm:mx-0 sm:h-10 sm:w-10",
      {
        "bg-red-100": props.type === ModalType.danger,
        "bg-yellow-100": props.type === ModalType.warning,
      },
    ]);
    const iconInnerClass = computed(() => [
      "h-6 w-6",
      {
        "text-red-600": props.type === ModalType.danger,
        "text-yellow-600": props.type === ModalType.warning,
      },
    ]);

    return {
      visibleInternal,
      iconOuterClass,
      iconInnerClass,
      ModalType,
    };
  },
});
</script>
