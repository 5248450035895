enum BadgeSize {
  normal = "normal",
  large = "large",
}
enum BadgeType {
  default = "default",
  success = "success",
  warning = "warning",
  danger = "danger",
  info = "info",
}

interface BadgeProps {
  label: string;
  size?: BadgeSize;
  type?: BadgeType;
}

export { BadgeSize, BadgeType, BadgeProps };
