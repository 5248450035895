<template>
  <div class="md:py-24 text-center">
    <h1
      class="mt-1 font-extrabold text-gray-900 text-2xl sm:text-3xl sm:tracking-tight"
    >
      {{ texts.unauthorized.title }}
    </h1>
    <p class="max-w-xl mt-5 mx-auto text-l text-gray-500">
      {{ texts.unauthorized.description }}
    </p>
  </div>
</template>
<script lang="ts">
import dictionary from "@/dictionary";
import { Culture } from "@/enums";
import { RootState } from "@/store";
import { defineComponent } from "vue";
import { useStore } from "vuex";

export default defineComponent({
  name: "Unauthorized",
  setup() {
    const store = useStore<RootState>();
    const texts = dictionary[store.getters["cultureStore/active"] as Culture];

    return { texts };
  },
});
</script>
