<template>
  <div class="mb-5">
    <slot name="heading"></slot>
    <slot></slot>
  </div>
</template>

<script lang="ts">
import { defineComponent } from "vue";

export default defineComponent({
  name: "Section",
});
</script>
