const redirectToOldDashboard = (tenantUri: string, token: string): void => {
  const form = document.createElement("form");
  form.hidden = true;
  form.action = `${process.env.VUE_APP_OLDDASHBOARD_URL}/${tenantUri}`;
  form.method = "POST";
  const input = document.createElement("input");
  input.name = "jwt";
  input.value = token;
  form.appendChild(input);
  document.body.appendChild(form);
  form.submit();
};

export { redirectToOldDashboard };
