
import { SearchIcon } from "@heroicons/vue/solid";
import { computed, defineComponent, PropType } from "vue";
import TextInput from "@/components/common/text-input/TextInput.vue";
import { TextInputType } from "@/components/common/text-input/TextInput.types";

export interface SearchProps {
  modelValue: string;
  placeholder?: string;
  debounceMs?: number;
}

export default defineComponent({
  props: {
    modelValue: {
      type: String as PropType<string>,
    },
    placeholder: {
      type: String as PropType<string>,
    },
    debounceMs: {
      type: Number as PropType<number>,
      default: 300,
    },
  },
  components: {
    TextInput,
  },
  setup(props, { emit }) {
    const debounceModelValue = debounce((value: string | undefined) => {
      emit("update:modelValue", value);
    }, props.debounceMs);

    const modelValueInternal = computed({
      get: () => props.modelValue,
      set: (value) => {
        debounceModelValue(value);
      },
    });
    return {
      modelValueInternal,
      TextInputType,
      SearchIcon,
    };
  },
});

function debounce<Args>(func: (...args: Args[]) => void, wait: number) {
  // https://levelup.gitconnected.com/debounce-in-javascript-improve-your-applications-performance-5b01855e086

  let timeout: number;
  return (...args: Args[]) => {
    const later = () => {
      clearTimeout(timeout);
      func(...args);
    };
    clearTimeout(timeout);
    timeout = setTimeout(later, wait);
  };
}
