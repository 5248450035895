<template>
  <div data-testid="application-shell">
    <div class="h-screen flex overflow-hidden bg-gray-100">
      <TransitionRoot as="template" :show="sidebarOpen">
        <Dialog
          as="div"
          static
          class="fixed inset-0 flex z-40 md:hidden"
          @close="sidebarOpen = false"
          :open="sidebarOpen"
        >
          <TransitionChild
            as="template"
            enter="transition-opacity ease-linear duration-300"
            enter-from="opacity-0"
            enter-to="opacity-100"
            leave="transition-opacity ease-linear duration-300"
            leave-from="opacity-100"
            leave-to="opacity-0"
          >
            <DialogOverlay class="fixed inset-0 bg-gray-600 bg-opacity-75" />
          </TransitionChild>
          <TransitionChild
            as="template"
            enter="transition ease-in-out duration-300 transform"
            enter-from="-translate-x-full"
            enter-to="translate-x-0"
            leave="transition ease-in-out duration-300 transform"
            leave-from="translate-x-0"
            leave-to="-translate-x-full"
          >
            <div
              class="relative flex-1 flex flex-col max-w-xs w-full pt-5 pb-4 bg-gray-800"
            >
              <TransitionChild
                as="template"
                enter="ease-in-out duration-300"
                enter-from="opacity-0"
                enter-to="opacity-100"
                leave="ease-in-out duration-300"
                leave-from="opacity-100"
                leave-to="opacity-0"
              >
                <div class="absolute top-0 right-0 -mr-12 pt-2">
                  <button
                    class="ml-1 flex items-center justify-center h-10 w-10 rounded-full focus:outline-none focus:ring-2 focus:ring-inset focus:ring-white"
                    @click="sidebarOpen = false"
                  >
                    <span class="sr-only">Close sidebar</span>
                    <XIcon class="h-6 w-6 text-white" aria-hidden="true" />
                  </button>
                </div>
              </TransitionChild>
              <div class="flex-shrink-0 flex items-center px-4">
                <img class="w-1/2" :src="summitLogoSrc" alt="summit-logo" />
              </div>
              <div class="mt-5 flex-1 h-0 overflow-y-auto">
                <nav class="px-2">
                  <div class="space-y-1">
                    <PrimaryNavigation />
                  </div>

                  <OtherNavigation />
                </nav>
              </div>
            </div>
          </TransitionChild>
          <div class="flex-shrink-0 w-14" aria-hidden="true">
            <!-- Dummy element to force sidebar to shrink to fit close icon -->
          </div>
        </Dialog>
      </TransitionRoot>

      <!-- Static sidebar for desktop -->
      <div class="hidden md:flex md:flex-shrink-0">
        <div class="flex flex-col w-64">
          <!-- Sidebar component, swap this element with another sidebar if you like -->
          <div class="flex flex-col h-0 flex-1">
            <div class="flex items-center h-16 flex-shrink-0 px-4 bg-gray-900">
              <img class="w-1/2" :src="summitLogoSrc" alt="summit-logo" />
            </div>
            <div class="flex-1 flex flex-col overflow-y-auto">
              <nav class="flex-1 px-2 py-4 bg-gray-800">
                <div class="space-y-1">
                  <PrimaryNavigation />
                </div>
                <OtherNavigation />
              </nav>
            </div>
          </div>
        </div>
      </div>
      <div class="flex flex-col w-0 flex-1 overflow-hidden">
        <div class="relative flex-shrink-0 flex h-16 bg-white shadow z-menuBar">
          <button
            class="px-4 border-r border-gray-200 text-gray-500 focus:outline-none focus:ring-2 focus:ring-inset focus:ring-brightgreen-500 md:hidden"
            @click="sidebarOpen = true"
          >
            <span class="sr-only">Open sidebar</span>
            <MenuAlt2Icon class="h-6 w-6" aria-hidden="true" />
          </button>
          <div class="flex-1 px-4 flex justify-between">
            <div class="flex-1 flex"></div>
            <div class="ml-4 flex items-center space-x-3 md:ml-6">
              <CulturePicker />
              <div class="text-sm font-medium" v-if="tenant">
                {{ tenant.name }}
              </div>
              <Menu
                as="div"
                class="relative"
                data-testid="profile-menu"
                v-if="user"
              >
                <div>
                  <MenuButton
                    class="bg-gray-800 flex text-sm rounded-full focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-offset-gray-800 focus:ring-white"
                  >
                    <span class="sr-only">Open user menu</span>
                    <img
                      data-testid="avatar"
                      class="h-8 w-8 rounded-full"
                      :src="user.picture"
                    />
                  </MenuButton>
                </div>
                <transition
                  enter-active-class="transition ease-out duration-100"
                  enter-from-class="transform opacity-0 scale-95"
                  enter-to-class="transform opacity-100 scale-100"
                  leave-active-class="transition ease-in duration-75"
                  leave-from-class="transform opacity-100 scale-100"
                  leave-to-class="transform opacity-0 scale-95"
                >
                  <MenuItems
                    data-testid="menu-items"
                    class="origin-top-right absolute right-0 mt-2 w-56 rounded-md shadow-lg bg-white ring-1 ring-black ring-opacity-5 divide-y divide-gray-100 focus:outline-none"
                  >
                    <div class="px-4 py-3">
                      <p class="text-sm">
                        {{ texts.profileMenuItems.signedInAs }}
                      </p>
                      <p class="text-sm font-medium text-gray-900 truncate">
                        {{ user.email }}
                      </p>
                    </div>
                    <div class="py-1">
                      <MenuItem v-slot="{ active }">
                        <RouterLinkAuth
                          to="/tenants"
                          :class="[
                            active ? 'bg-gray-100' : '',
                            'group flex items-center justify-between px-4 py-2 text-sm',
                          ]"
                        >
                          <span
                            :class="[
                              active ? 'text-gray-900' : 'text-gray-700',
                            ]"
                            >{{ texts.profileMenuItems.switchTenant }}</span
                          >
                          <SwitchHorizontalIcon
                            :class="[
                              'mr-3 h-5 w-5',
                              active
                                ? 'text-brightgreen-600'
                                : 'text-brightgreen-500',
                            ]"
                            aria-hidden="true"
                          />
                        </RouterLinkAuth>
                      </MenuItem>
                      <MenuItem v-slot="{ active }">
                        <button
                          :class="[
                            active
                              ? 'bg-gray-100 text-gray-900'
                              : 'text-gray-700',
                            'block w-full text-left px-4 py-2 text-sm',
                          ]"
                          @click="logout"
                        >
                          {{ texts.profileMenuItems.signout }}
                        </button>
                      </MenuItem>
                    </div>
                  </MenuItems>
                </transition>
              </Menu>
            </div>
          </div>
        </div>

        <main class="flex-1 relative overflow-y-auto focus:outline-none">
          <div class="pt-6 mx-auto px-1 sm:px-6 lg:px-8">
            <slot></slot>
          </div>
        </main>
      </div>
    </div>
  </div>
</template>

<script lang="ts">
import CulturePicker from "./CulturePicker.vue";
import { computed, defineComponent, inject, ref } from "vue";
import {
  Dialog,
  DialogOverlay,
  TransitionChild,
  TransitionRoot,
  Menu,
  MenuButton,
  MenuItem,
  MenuItems,
} from "@headlessui/vue";

import PrimaryNavigation from "./primary-navigation/PrimaryNavigation.vue";
import OtherNavigation from "./OtherNavigation.vue";

import summitLogoSrc from "@/assets/images/logo-summit.svg";
import {
  XIcon,
  MenuAlt2Icon,
  SwitchHorizontalIcon,
} from "@heroicons/vue/outline";

import dictionary from "@/dictionary";
import { Culture } from "@/enums";
import { useStore } from "vuex";
import { RootState } from "@/store";
import { IAuthPlugin, User } from "@/plugins/authentication";
import RouterLinkAuth from "@/components/common/router-link-auth/RouterLinkAuth.vue";
import { Tenant } from "@/models/tenant";

export default defineComponent({
  name: "ApplicationShell",
  props: {},
  components: {
    CulturePicker,
    PrimaryNavigation,
    Dialog,
    DialogOverlay,
    TransitionChild,
    TransitionRoot,
    MenuAlt2Icon,
    SwitchHorizontalIcon,
    XIcon,
    Menu,
    MenuButton,
    MenuItem,
    MenuItems,
    RouterLinkAuth,
    OtherNavigation,
  },

  setup() {
    const auth = inject<IAuthPlugin>("Auth");
    if (!auth) throw new Error("Missing auth plugin");

    const store = useStore<RootState>();
    const user = computed<User | null>(() => store.getters["authStore/user"]);
    const tenant = computed<Tenant | null>(
      () => store.getters["authStore/tenant"]
    );
    const texts = dictionary[store.getters["cultureStore/active"] as Culture];
    const sidebarOpen = ref(false);

    return {
      summitLogoSrc,
      sidebarOpen,
      user,
      tenant,
      logout: auth.logout,
      texts,
    };
  },
});
</script>
