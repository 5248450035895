<template>
  <div class="pb-5 mb-5 border-b border-gray-200"></div>
</template>

<script lang="ts">
import { defineComponent } from "vue";

export default defineComponent({
  name: "SectionDivider",
});
</script>
