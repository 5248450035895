import Account from "@/models/account";
import { Tenant, TenantDTO } from "@/models/tenant";
import HttpClient from "@/utils/httpClient";
import { User } from "@auth0/auth0-spa-js";
import {
  ActionContext,
  ActionTree,
  GetterTree,
  Module,
  MutationTree,
} from "vuex";
import { RootState } from "..";

interface AuthStoreState {
  loading: boolean;
  error: unknown;
  loadingTenants: boolean;
  errorLoadingTenants: boolean;
  isAuthenticated: boolean;
  user: User | null;
  permissions: Array<Permission>;
  token: string | null;
  tenant: Tenant | null;
  redirectingToOldDashboard: boolean;
  hostLoginId: number | null;
  errorLoadingTenant: boolean;
  tenants: Tenant[];
  isUnauthorizedSurfConextUser: boolean;
}

const state: AuthStoreState = {
  loading: true,
  error: null,
  loadingTenants: false,
  errorLoadingTenants: false,
  isAuthenticated: false,
  user: null,
  permissions: [],
  token: null,
  tenant: null,
  redirectingToOldDashboard: false,
  hostLoginId: null,
  errorLoadingTenant: false,
  tenants: [],
  isUnauthorizedSurfConextUser: false,
};

export enum Permission {
  ActivitiesRead = "activities:read",
  ActivitiesWrite = "activities:write",
  LocationsRead = "locations:read",
  LocationsWrite = "locations:write",
  HostsRead = "hosts:read",
  HostsWrite = "hosts:write",
  UserManagementRead = "usermanagement:read",
  UserManagementWrite = "usermanagement:write",
  TenantsRead = "tenants:read",
}

interface AuthStoreGetters extends GetterTree<AuthStoreState, RootState> {
  loading: (state: AuthStoreState) => boolean;
  error: (state: AuthStoreState) => unknown;
  loadingTenants: (state: AuthStoreState) => boolean;
  errorLoadingTenants: (state: AuthStoreState) => boolean;
  isAuthenticated: (state: AuthStoreState) => boolean;
  user: (state: AuthStoreState) => User | null;
  userMatchesAccount: (state: AuthStoreState) => (account: Account) => boolean;
  permissions: (state: AuthStoreState) => Array<Permission>;
  tenant: (state: AuthStoreState) => Tenant | null;
  token: (state: AuthStoreState) => string | null;
  redirectingToOldDashboard: (state: AuthStoreState) => boolean;
  hostLoginId: (state: AuthStoreState) => number | null;
  errorLoadingTenant: (state: AuthStoreState) => boolean;
  tenants: (state: AuthStoreState) => Tenant[];
  isUnauthorizedSurfConextUser: (state: AuthStoreState) => boolean;
}

const getters: AuthStoreGetters = {
  loading(state) {
    return state.loading;
  },
  error(state) {
    return state.error;
  },
  loadingTenants: (state) => {
    return state.loadingTenants;
  },
  errorLoadingTenants: (state) => {
    return state.errorLoadingTenants;
  },
  isAuthenticated(state) {
    return state.isAuthenticated;
  },
  user(state) {
    return state.user;
  },
  userMatchesAccount: (state) => (account: Account) => {
    return account.id === state.user?.sub;
  },
  permissions(state) {
    return state.permissions;
  },
  tenant(state) {
    return state.tenant;
  },
  token(state) {
    return state.token;
  },
  redirectingToOldDashboard(state) {
    return state.redirectingToOldDashboard;
  },
  hostLoginId(state) {
    return state.hostLoginId;
  },
  errorLoadingTenant(state) {
    return state.errorLoadingTenant;
  },
  tenants(state) {
    return state.tenants;
  },
  isUnauthorizedSurfConextUser(state) {
    return state.isUnauthorizedSurfConextUser;
  },
};

interface ILoginResult {
  isAuthenticated: boolean;
  user: User | null;
  permissions: Array<Permission>;
  token: string | null;
  tenantUri: string | null;
  redirectingToOldDashboard: boolean;
  hostLoginId: number | null;
  isUnauthorizedSurfConextUser: boolean;
}

interface AuthStoreMutations extends MutationTree<AuthStoreState> {
  setLoading(state: AuthStoreState, loading: boolean): void;
  setError(state: AuthStoreState, error: unknown): void;
  setLoadingTenants(state: AuthStoreState, loadAccounts: boolean): void;
  setErrorLoadingTenants(
    state: AuthStoreState,
    errorLoadingAccounts: boolean
  ): void;
  setLoginResult(state: AuthStoreState, result: ILoginResult): void;
  setRedirectingToOldDashboard(
    state: AuthStoreState,
    redirecting: boolean
  ): void;
  setTenants(state: AuthStoreState, tenants: Tenant[]): void;
  setTenant(state: AuthStoreState, tenant: Tenant | null): void;
  setErrorLoadingTenant(
    state: AuthStoreState,
    errorLoadingTenant: boolean
  ): void;
}

const mutations: AuthStoreMutations = {
  setLoading(state, loading: boolean) {
    state.loading = loading;
  },
  setError(state, error: unknown): void {
    state.error = error;
  },
  setLoadingTenants(state, loadingTenants) {
    state.loadingTenants = loadingTenants;
  },
  setErrorLoadingTenants(state, errorLoadingTenants) {
    state.errorLoadingTenants = errorLoadingTenants;
  },
  setLoginResult(state, result: ILoginResult) {
    state.isAuthenticated = result.isAuthenticated;
    state.user = result.user;
    state.permissions = result.permissions;
    state.token = result.token;
    state.redirectingToOldDashboard = result.redirectingToOldDashboard;
    state.hostLoginId = result.hostLoginId;
    state.isUnauthorizedSurfConextUser = result.isUnauthorizedSurfConextUser;
  },
  setRedirectingToOldDashboard(state, redirecting: boolean) {
    state.redirectingToOldDashboard = redirecting;
  },
  setTenants(state, tenants: Tenant[]) {
    state.tenants = tenants;
  },
  setTenant(state, tenant: Tenant | null) {
    state.tenant = tenant;
  },
  setErrorLoadingTenant(state, errorLoadingTenant) {
    state.errorLoadingTenant = errorLoadingTenant;
  },
};

interface AuthStoreActions extends ActionTree<AuthStoreState, RootState> {
  login(
    context: ActionContext<AuthStoreState, RootState>,
    result: ILoginResult
  ): void;
  loadTenants(context: ActionContext<AuthStoreState, RootState>): Promise<void>;
  switchTenant(
    context: ActionContext<AuthStoreState, RootState>,
    toUri: string
  ): void;
}

const actions: AuthStoreActions = {
  async login(
    context: ActionContext<AuthStoreState, RootState>,
    result: ILoginResult
  ) {
    context.commit("setLoginResult", result);
    HttpClient.setAuth(state.token);
    if (result.tenantUri) {
      await context.dispatch("switchTenant", result.tenantUri);
    }
  },
  async loadTenants(context: ActionContext<AuthStoreState, RootState>) {
    try {
      context.commit("setErrorLoadingTenants", false);
      context.commit("setLoadingTenants", true);
      const response = await HttpClient.getTenants();
      const tenants = response.data.map((dto) => new Tenant(dto));

      context.commit("setTenants", tenants);
    } catch (error) {
      context.commit("setErrorLoadingTenants", true);
      throw error;
    } finally {
      context.commit("setLoadingTenants", false);
    }
  },
  async switchTenant(
    context: ActionContext<AuthStoreState, RootState>,
    tenantUri: string
  ) {
    try {
      context.commit("setErrorLoadingTenant", false);
      HttpClient.setTenant(tenantUri);
      const tenantDto = await HttpClient.instance.get<TenantDTO>("tenant");
      const tenant = new Tenant(tenantDto.data);
      context.commit("setTenant", tenant);
    } catch (error) {
      context.commit("setErrorLoadingTenant", true);
      HttpClient.setTenant(null);
      context.commit("setTenant", null);
      throw error;
    }
  },
};

interface AuthStore extends Module<AuthStoreState, RootState> {
  namespaced: true;
  state?: AuthStoreState;
  getters: AuthStoreGetters;
  mutations: AuthStoreMutations;
  actions: AuthStoreActions;
}

const authModule: AuthStore = {
  namespaced: true,
  state,
  getters,
  mutations,
  actions,
};

export { AuthStore, AuthStoreState, ILoginResult };
export default authModule;
