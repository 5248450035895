import { RouteNames } from "@/router/routeNames";
import {
  NavigationGuard,
  NavigationGuardNext,
  RouteLocationNormalized,
} from "vue-router";
import { userCanGoTo } from "../helpers/userCanGoTo";

const authorizationGuard: NavigationGuard = (
  to: RouteLocationNormalized,
  from: RouteLocationNormalized,
  next: NavigationGuardNext
) => {
  const userCanGoToLocation = userCanGoTo(to);

  if (userCanGoToLocation) {
    return next();
  } else {
    return next({ name: RouteNames.UNAUTHORIZED });
  }
};

export { authorizationGuard };
