
import { defineComponent } from "@vue/runtime-core";
import { useStore } from "vuex";
import { RootState } from "@/store";
import dictionary from "@/dictionary";
import { Culture } from "@/enums";

export default defineComponent({
  name: "Error",
  setup() {
    const store = useStore<RootState>();
    const texts = dictionary[store.getters["cultureStore/active"] as Culture];

    return { texts };
  },
});
