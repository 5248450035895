<template>
  <Listbox as="div" v-model="selected" :disabled="disabled">
    <ListboxLabel
      v-if="label"
      data-testid="label"
      class="block text-sm font-medium text-gray-700"
    >
      {{ label }}
    </ListboxLabel>
    <div class="mt-1 relative">
      <ListboxButton
        data-testid="button"
        :class="[
          'relative w-full border border-gray-300 rounded-md shadow-sm pl-3 pr-10 py-2 text-left cursor-default focus:outline-none focus:ring-1 focus:ring-brightgreen-500 focus:border-brightgreen-500 sm:text-sm',
          inputClass,
        ]"
      >
        <span class="w-full inline-flex truncate">
          <span data-testid="button-text" class="truncate">{{
            selected && selected.value ? selected.label : optionsPlaceholder
          }}</span>
          <span
            class="ml-2 truncate text-gray-500"
            v-if="selected && selected.value && selected.subText"
            >{{ selected.subText }}</span
          >
        </span>

        <span
          class="absolute inset-y-0 right-0 flex items-center pr-2 pointer-events-none"
        >
          <SelectorIcon class="h-5 w-5 text-gray-400" aria-hidden="true" />
        </span>
      </ListboxButton>

      <transition
        leave-active-class="transition ease-in duration-100"
        leave-from-class="opacity-100"
        leave-to-class="opacity-0"
      >
        <ListboxOptions
          data-testid="options"
          class="absolute z-10 mt-1 w-full bg-white shadow-lg max-h-60 rounded-md py-1 text-base ring-1 ring-black ring-opacity-5 overflow-auto focus:outline-none sm:text-sm"
        >
          <ListboxOption
            data-testid="option-unset"
            :value="null"
            v-slot="{ active, selected }"
            v-if="allowUnset"
          >
            <li
              :class="[
                { 'bg-brightgreen-500': active },
                'cursor-default select-none relative py-2 pl-3 pr-9',
              ]"
            >
              <div class="flex">
                <span data-testid="option-text">&nbsp;</span>
              </div>

              <span
                v-if="selected"
                :class="[
                  active ? 'text-white' : 'text-brightgreen-600',
                  'absolute inset-y-0 right-0 flex items-center pr-4',
                ]"
              >
                <CheckIcon class="h-5 w-5" aria-hidden="true" />
              </span></li
          ></ListboxOption>

          <ListboxOption
            as="template"
            v-for="(option, index) in options"
            data-testid="option"
            :key="index.toString()"
            :value="option"
            v-slot="{ active, selected }"
            :disabled="option.disabled"
          >
            <li
              :class="[
                active ? 'text-white bg-brightgreen-500' : 'text-gray-900',
                'cursor-default select-none relative py-2 pl-3 pr-9',
                { 'opacity-50': option.disabled },
              ]"
            >
              <div class="flex">
                <span
                  data-testid="option-text"
                  :class="[
                    selected ? 'font-semibold' : 'font-normal',
                    'truncate',
                  ]"
                >
                  {{ option.label || "&nbsp;" }}
                </span>
                <span
                  data-testid="option-subtext"
                  :class="[
                    active ? 'text-indigo-200' : 'text-gray-500',
                    'ml-2 truncate',
                  ]"
                  v-if="option.subText"
                >
                  {{ option.subText }}
                </span>
              </div>

              <span
                v-if="selected"
                :class="[
                  active ? 'text-white' : 'text-brightgreen-600',
                  'absolute inset-y-0 right-0 flex items-center pr-4',
                ]"
              >
                <CheckIcon class="h-5 w-5" aria-hidden="true" />
              </span>
            </li>
          </ListboxOption>
        </ListboxOptions>
      </transition>
    </div>
  </Listbox>
</template>

<script lang="ts">
import {
  Listbox,
  ListboxButton,
  ListboxLabel,
  ListboxOption,
  ListboxOptions,
} from "@headlessui/vue";
import { CheckIcon, SelectorIcon } from "@heroicons/vue/solid";
import { computed, defineComponent, PropType } from "vue";
import { SelectOption } from "./SelectOption";

export interface SelectProps {
  options: SelectOption[];
  optionsPlaceholder?: string;
  label?: string;
  modelValue?: SelectOption;
  disabled?: boolean;
  allowUnset?: boolean;
}

export default defineComponent({
  components: {
    Listbox,
    ListboxButton,
    ListboxLabel,
    ListboxOption,
    ListboxOptions,
    CheckIcon,
    SelectorIcon,
  },
  props: {
    options: {
      type: Array as PropType<SelectOption[]>,
      required: true,
      validator: (value: []) => {
        return value.length > 0;
      },
    },
    optionsPlaceholder: {
      type: String,
    },
    label: {
      type: String,
    },
    modelValue: {
      type: [Object, null] as PropType<SelectOption | null>,
    },
    disabled: {
      type: Boolean,
    },
    allowUnset: {
      type: Boolean,
    },
  },
  setup(props, { emit }) {
    const selected = computed<SelectOption | null | undefined>({
      get: () => props.modelValue,
      set: (value) => emit("update:modelValue", value),
    });

    const inputClass = computed(() => [
      {
        "text-gray-500 placeholder-gray-300 bg-gray-100": props.disabled,
      },
      {
        "bg-white": !props.disabled,
      },
    ]);

    return {
      selected,
      inputClass,
    };
  },
});
</script>
